// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ExampleComponent_popup-background__QTZK8 {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5); /* Темный фон */
    display: flex;
    justify-content: center;
    align-items: center;
}

.ExampleComponent_map-wrapper__UP93B {
    display: flex;
    justify-content: space-between;
    width: 90vw;
    height: 90vh;
    background-color: white;
    border-radius: 16px;
    padding: 20px;
}

.ExampleComponent_map-draw__\\+rKy5, .ExampleComponent_map-svg__pXFeO {
    width: 45%;
    height: 100%;
}

.ExampleComponent_map-draw__\\+rKy5 h3, .ExampleComponent_map-svg__pXFeO h3 {
    text-align: center;
    margin-bottom: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Example/ExampleComponent.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,YAAY;IACZ,aAAa;IACb,oCAAoC,EAAE,eAAe;IACrD,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,WAAW;IACX,YAAY;IACZ,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;AACjB;;AAEA;IACI,UAAU;IACV,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;AACvB","sourcesContent":[".popup-background {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100vw;\n    height: 100vh;\n    background-color: rgba(0, 0, 0, 0.5); /* Темный фон */\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.map-wrapper {\n    display: flex;\n    justify-content: space-between;\n    width: 90vw;\n    height: 90vh;\n    background-color: white;\n    border-radius: 16px;\n    padding: 20px;\n}\n\n.map-draw, .map-svg {\n    width: 45%;\n    height: 100%;\n}\n\n.map-draw h3, .map-svg h3 {\n    text-align: center;\n    margin-bottom: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popup-background": `ExampleComponent_popup-background__QTZK8`,
	"map-wrapper": `ExampleComponent_map-wrapper__UP93B`,
	"map-draw": `ExampleComponent_map-draw__+rKy5`,
	"map-svg": `ExampleComponent_map-svg__pXFeO`
};
export default ___CSS_LOADER_EXPORT___;
