import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';

export interface User {
    id: number;
    surname: string;
    firstname: string;
    avatar: string;
    email: string;
}

interface AuthState {
    user: User | null;
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
}

const initialState: AuthState = {
    user: null,
    status: 'idle',
    error: null
};

interface Credentials {
    username: string;
    password: string;
}

export const login = createAsyncThunk('auth/login', async (credentials: Credentials) => {
    const response = await axios.post(`https://api-moydvor.sakla.ru/api/login?email=${credentials.username}&password=${credentials.password}`);
    return response.data;
});

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        logout: (state) => {
            state.user = null;
            state.status = 'idle';
            state.error = null;
            localStorage.removeItem('user');
        },
        setUser(state, action: PayloadAction<User>) {
            state.user = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(login.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(login.fulfilled, (state, action: PayloadAction<User>) => {
                state.status = 'succeeded';
                state.user = action.payload;
                console.log(action.payload);
                localStorage.setItem('user', JSON.stringify(action.payload));
            })
            .addCase(login.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message || 'Failed to login';
            });
    }
});

export const { logout, setUser } = authSlice.actions;

export default authSlice.reducer;
