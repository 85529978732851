import React, {useState} from 'react';
import styles from './Instruction.module.scss';

const Instruction = () => {

    const images = [
        {
            headerBig: 'Инструкция',
            description: 'На вкладке «Мой Безопасный Двор» найдите свой двор на карте, добавьте метки на важные объекты и следите за метками других пользователей, чтобы оставаться информированными об изменениях в вашем дворе.',
            image: '/images/slider/Слайд 1.jpg'
        },
        {
            miniHeader: 'Шаг 1: Добавление двора',
            description: 'Для добавления (создания) нового безопасного двора, нажмите на кнопку «ДОБАВИТЬ ДВОР», которая расположена в нижней части экрана. ',
            image: '/images/slider/Слайд 2.jpg'
        },
        {
            miniHeader: 'Шаг 2: Поиск двора',
            description: 'Найдите свой двор введя в строке поиска необходимый адрес, либо используйте интерактивную карту для навигации. Найдя нужный двор, кликните на него. ',
            image: '/images/slider/Слайд 3.jpg'
        },
        {
            miniHeader: 'Шаг 3: Подтверждение выбора',
            description: 'Выбрав местоположение необходимого двора (кликнув на карту или введя адрес), рядом с поисковой строкой появится галочка. Нажмите на галочку, чтобы подтвердить выбранное местоположение. После этого появится всплывающее окно, где вы сможете более детально настроить метку.',
            image: '/images/slider/Слайд 4.jpg'
        },
        {
            miniHeader: 'Шаг 4: Конструирование безопасного двора',
            description: 'Нажав кнопку «Добавить объект» перед вами всплывет окно с объектами безопасного двора для обозначения их на карте: Арка, Игровая зона, Подъезд, Парковка, Выход с игровой площадки, Проезжая часть.  ',
            image: '/images/slider/Слайд 5.jpg'
        },
        {
            miniHeader: 'Шаг 5: Добавление объекта «Арки»',
            description: 'При наличии арок во дворе, необходимо кликнуть мышью на элемент «Арка», навести мышь на точку на карте, где располагается арка, кликнуть. После нажатия на клавишу мыши появится возможность «Подтвердить». Если нужно внести изменения, кликните на объект и выберите «Удалить», затем повторите процесс.',
            image: '/images/slider/Слайд 6.jpg'
        },
        {
            miniHeader: 'Шаг 6: Добавление объекта «Игровая зона»',
            description: 'Наведите мышью на точку начала игровой зоны на карте. Кликая мышью очертите на карте границы игровой зоны. После завершения, нажмите кнопку «Подтвердить». Если нужно внести изменения, кликните на объект и выберите «Удалить», затем повторите процесс.',
            image: '/images/slider/Слайд 7.jpg'
        },
        {
            miniHeader: 'Шаг 7: Добавление объекта «Подъезд»',
            description: 'При наличии подъездов во дворе, необходимо кликнуть мышью на элемент «Подъезд», навести мышь на точку на карте, где располагается подъезд, кликнуть. После нажатия на клавишу мыши появится возможность «Подтвердить» локацию подъезда. Если нужно внести изменения, кликните на объект и выберите «Удалить», затем повторите процесс.',
            image: '/images/slider/Слайд 8.jpg'
        },
        {
            miniHeader: 'Шаг 8: Добавление объекта «Парковка» ',
            description: 'Наведите мышью на точку начала зоны парковки на карте. Кликая мышью очертите на карте границы зоны парковки автомобилей. После завершения, нажмите кнопку «Подтвердить». Если нужно внести изменения, кликните на объект и выберите «Удалить», затем повторите процесс.',
            image: '/images/slider/Слайд 9.jpg'
        },
        {
            miniHeader: 'Шаг 9. Добавление объекта «Выход с игровой площадки» ',
            description: 'При наличии игровой площадки во дворе, необходимо кликнуть мышью на элемент «Выход с игровой площадки». Наведите мышь на точку на карте, где располагается выход, и кликните. После нажатия появится возможность «Подтвердить» локацию выхода. Если нужно внести изменения, кликните на объект и выберите «Удалить», затем повторите процесс.',
            image: '/images/slider/Слайд 10.jpg'
        },
        {
            miniHeader: 'Шаг 10. Добавление объекта «Проезжая часть»',
            description: 'Наведите мышью на точку начала зоны проезжей части на карте. Кликая мышью очертите на карте границы проезжей части. После завершения, нажмите кнопку «Подтвердить». Если нужно внести изменения, кликните на объект и выберите «Удалить», затем повторите процесс.',
            image: '/images/slider/Слайд 11.jpg'
        },
        {
            miniHeader: 'Шаг 11. Добавление фото ',
            description: 'После добавления на карту двора всех необходимых объектов, нажмите кнопку зону «показать картинку зоны со знаком +» для добавления фотографий двора. ВНИМАНИЕ! Формат изображения для адекватного отображения должен быть: jpg, png. Размер фотографии не должен превышать 5 МБ.',
            image: '/images/slider/Слайд 12.jpg'
        },
        {
            miniHeader: 'Шаг 12. Публикация метки на карту',
            description: 'После добавления всех объектов и фотографии, нажмите «Добавить метку». Метка отобразится на общей карте, позволяя другим пользователям видеть её расположение.',
            image: '/images/slider/Слайд 13.jpg'
        },
    ];

    const [currentSlide, setCurrentSlide] = useState(0);

    const nextSlide = () => {
        setCurrentSlide((prevSlide) => (prevSlide === images.length - 1 ? 0 : prevSlide + 1));
    };

    const prevSlide = () => {
        setCurrentSlide((prevSlide) => (prevSlide === 0 ? images.length - 1 : prevSlide - 1));
    };

    return (
        <div className={styles.instruction_background}>
            <div className={styles.instruction_content}>
                <div className={styles.slider}>
                    <div className={styles.slider_image}>
                        <img src={images[currentSlide].image} alt=""/>
                    </div>
                    <div className={styles.slider_info}>
                        {images[currentSlide].headerBig ?
                            (
                                <div className={styles.headerBig_info}>
                                    <h2 className={styles.headerBig}>{images[currentSlide].headerBig}</h2>
                                    <p className={styles.description}>{images[currentSlide].description}</p>
                                </div>
                            ) : (
                                <>
                                    <h2 className={styles.miniHeader}>{images[currentSlide].miniHeader}</h2>
                                    <p className={styles.description}>{images[currentSlide].description}</p>
                                </>
                            )
                        }
                    </div>
                    <div className={styles.rounds}>
                        {images.map((round, index) => (
                            <div
                                key={index}
                                className={index === currentSlide ? styles.roundActive : styles.round}
                                onClick={() => setCurrentSlide(index)}
                            />
                        ))}
                    </div>
                    <div className={`${styles.control} ${styles.control_left}`} onClick={prevSlide}>
                        <img src='/images/slider/leftControl.svg' alt='slider_left'/>
                    </div>
                    <div className={`${styles.control} ${styles.control_right}`} onClick={nextSlide}>
                        <img src='/images/slider/rightControl.svg' alt='slider_right'/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Instruction;
