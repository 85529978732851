import React, {useEffect, useRef, useState} from 'react';
import MapComp from "../Components/Map";
import AddressSearchInput from "../Components/AddressSearchInput/AddressSearchInput";
import {MapContainer} from "react-leaflet";
import ImageConstructor from "../Components/ImageConstructor/ImageConstructor";
import {useSelector} from "react-redux";
import {RootState} from "../store/store";
import LoginPopup from "../Components/LoginPopup/LoginPopup";
import { Map as LeafletMap } from 'leaflet';
import {Mark} from "../store/markSlice";
import YardScreen from "../Components/YardScreen/YardScreen";
import HelloPopup from "../Components/HelloPopup/HelloPopup";

function formatAddress(address: string) {
    const parts = address.split(', ');
    const houseNumber = parts[0];
    const streetName = parts[1];
    return `${streetName}, ${houseNumber}`;
}

const Main = () => {

    const [selectedAddress, setSelectedAddress] = useState<string>('');
    const [addressToPopup, setAddressToPopup] = useState<string>('');
    const [showSearch, setShowSearch] = useState(false);
    const [isAddingMarker, setIsAddingMarker] = useState<boolean>(false);
    const [formattedAddress, setFormattedAddress] = useState<string>('')
    const [cleanMarker, setCleanMarker] = useState<boolean>(false);
    const [showPopup, setShowPopup] = useState<boolean>(false);
    const [showYardPopup, setShowYardPopup] = useState<boolean>(false);
    const [markerPosition, setMarkerPosition] = useState<[number, number] | null>(null)
    const [showLogin, setShowLogin] = useState<boolean>(false);
    const [showHello, setShowHello] = useState<boolean>(false);
    const [mark, setMark] = useState<Mark | null>(null);
    const [active, setActive] = useState('all');

    const [center, setCenter] = useState<[number, number]>([55.783063, 49.119782]);

    const mapRef = useRef<LeafletMap | null>(null);

    const { marks, user } = useSelector((state: RootState) => ({
        marks: state.marks.marks,
        user: state.auth.user,
    }));

    useEffect(() => {
        if (mapRef.current) {
            mapRef.current.setView(center); // Устанавливаем новые координаты
        }
    }, [center]);

    useEffect(() => {
        // Здесь можете добавить логику при изменении состояния center
        console.log('Центр изменен на:', center);

        // Например, переход к следующему состоянию или выполнение каких-либо действий
        // setNextStateBasedOnCenter(center);

    }, [center]);

    const closePopup = () => setShowPopup(false);
    const closeYardPopup = () => setShowYardPopup(false);

    const handleAddressToPopup = (address: string) => {
        setAddressToPopup(address);
    }

    const handleAddressSelect = (address: string) => {
        setSelectedAddress(address);
    };

    const handleClickButton = () => {
        if (!user) {
            setShowLogin(true);
        } else {
            setShowSearch(true);
            setIsAddingMarker(true);
        }
    }

    useEffect(() => {
        if (user) {
            setShowLogin(false)
        }
    }, [user])

    const handleSendAddress = (address: string) => {
        const formattingAddress = formatAddress(address);
        setFormattedAddress(formattingAddress);
    }

    const handleSetAddingMarker = () => {
        setCleanMarker(true);
    }

    const handleCleanFalse = () => {
        setCleanMarker(false);
    }

    const handelShowPopup = () => {
        setShowPopup(true);
    }

    const handleMarkerPosition = (changedMarkerPosition: [number, number] | null) => {
        setMarkerPosition(changedMarkerPosition)
    }

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'Escape' && showSearch && isAddingMarker && !showPopup) {
                setIsAddingMarker(false);
                setShowSearch(false);
                setFormattedAddress('');
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [showSearch, isAddingMarker, showPopup]);

    const handleMapReady = (map: LeafletMap | null) => {
        mapRef.current = map; // Сохраняем объект карты в ref
        if (map) {
            map.setView(center, 16);
        }
    };

    const handleMarkerClick = (mark: Mark) => {
        // Обработка клика по маркеру
        console.log('Кликнули по маркеру:', mark);
        setMark(mark);
        setShowYardPopup(true);
        // Здесь можно добавить дополнительную логику, например, обновить состояние
    };

    return (
        <div className='main'>
            <MapContainer className='main_map'
                // @ts-ignore
                          center={center}
                          zoom={8}
                          style={{ height: '100%', width: '100%', zIndex: '1' }}
                          whenReady={() => handleMapReady(mapRef.current)}
            >
                <MapComp user={user} coordinates={center} zoom={13} address={selectedAddress} isAddingMarker={isAddingMarker} sendAddress={handleSendAddress} cleanMarker={cleanMarker} handleCleanFalse={handleCleanFalse} handleMarkerPosition={handleMarkerPosition} onMarkerClick={handleMarkerClick} active={active}></MapComp>
            </MapContainer>
            {
             user && (
                    <div className='toggle-switch'>
                        <button
                            className={`toggle-button ${active === 'all' ? 'active' : ''}`}
                            onClick={() => setActive('all')}
                        >
                            Все метки
                        </button>
                        <button
                            className={`toggle-button ${active === 'my' ? 'active' : ''}`}
                            onClick={() => setActive('my')}
                        >
                            Мои метки
                        </button>
                    </div>
                )
            }
            <ImageConstructor isOpen={showPopup} onClose={closePopup} address={addressToPopup} markerPosition={markerPosition}></ImageConstructor>
            {mark && (
                <YardScreen isOpen={showYardPopup} onClose={closeYardPopup} mark={mark}></YardScreen>
            )}
            {
                showSearch && (
                    <div className='infoBlock'>
                        <img src="/images/info.svg" alt=""/>
                        <span>
                            Укажите точку на карте или введите адрес в поисковой строке. Для выхода из режима добавления двора нажмите <span className='esc'>ESC</span>
                        </span>
                    </div>
                )
            }
            {!showSearch ? (
                <button className='main_button' onClick={handleClickButton}>
                    ДОБАВИТЬ ДВОР
                </button>
            ) : (
                <AddressSearchInput onAddressSelect={handleAddressSelect} formattedAddress={formattedAddress} handleSetAddingMarker={handleSetAddingMarker} handelShowPopup={handelShowPopup} handleAddressToPopup={handleAddressToPopup}></AddressSearchInput>
            )}
            {showLogin && <LoginPopup onSuccess={() => setShowHello(true)} onClose={() => setShowLogin(false)} />}
            {showHello && <HelloPopup onClose={() => setShowHello(false)}/>}
        </div>
    );
};

export default Main;
