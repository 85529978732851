import React from 'react';
import {Link} from "react-router-dom";
import styles from './HelloPopup.module.scss';

interface HelloPopupProps {
    onClose: () => void;
}

const HelloPopup: React.FC<HelloPopupProps> = ({onClose}) => {
    return (
        <div className={styles.popupOverlay} onClick={onClose}>
            <div className={styles.popupContent} onClick={(e) => e.stopPropagation()}>
                <button className={styles.closeButton} onClick={onClose}>
                    <img src='/images/closeICON.svg' alt='close icon svg'/>
                </button>
                <h2>Добро пожаловать на вкладку  «Мой Безопасный Двор»! </h2>
                <img src="/images/map.svg" alt=""/>
                <p>
                    Здесь у каждого пользователя есть возможность создания схемы двора с указанием ловушек и элементов дороги.
                    Ознакомься с инструкцией, создай «Свой Безопасный Двор», распечатай его, и повесь у себя в подъезде!
                </p>
                <Link to='/instruction'>
                    <div className={styles.instructionButton}>
                        ИНСТРУКЦИЯ
                    </div>
                </Link>
            </div>
        </div>
    );
};

export default HelloPopup;