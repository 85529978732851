import React, {useEffect, useState} from 'react';
import {MapContainer, TileLayer, Marker, Popup, LayersControl, useMap, useMapEvents} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
//@ts-ignore
import L from 'leaflet';
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import {SearchResult} from "leaflet-geosearch/lib/providers/provider";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../store/store";
import {fetchMarks, deleteMark, Mark} from "../store/markSlice";
import {useAppDispatch} from "../store/hooks";
import JustMap from "./JustMap";
import {User} from "../store/authSlice";
import defaultAvatar from '../stdavatar.svg'

const { BaseLayer } = LayersControl;

interface MapCompProps {
    user: User | null;
    coordinates: [number, number];
    zoom: number;
    address: string;
    isAddingMarker: boolean;
    sendAddress: (address: string) => void;
    cleanMarker: boolean;
    handleCleanFalse: () => void;
    handleMarkerPosition: (coordinates: [number, number] | null) => void;
    onMarkerClick: (mark: Mark) => void;
    active: string;
}

const customMarkerIcon = new L.Icon({
    iconUrl: '/images/Marker.svg',
    iconSize: [40, 61]
});

const createCustomIcon = (imageUrl: string) => {
    // const crossIconHTML = crossShow === true ? '<img src="/images/cross.svg" class="cross-icon" />' : '';

    return L.divIcon({
        html: `
            <div class="customMark-container">
                <div class="customMark">
                    <div class="image_container">
                        <img src="${imageUrl ? imageUrl : defaultAvatar}" alt=""/>
                    </div>
                </div>
            </div>`,
        className: ''
    });
};

interface ICustomMarker {
    position: [number, number];
    imageUrl: string;
    onClick?: () => void;
    onMouseOver?: () => void;
    onMouseOut?: () => void;
    // crossShow: boolean | null;
}


const CustomMarker: React.FC<ICustomMarker> = ({ position, imageUrl, onClick, onMouseOver, onMouseOut }) => {
    const map = useMap();

    useEffect(() => {
        const icon = createCustomIcon(imageUrl);
        const marker = L.marker(position, { icon }).addTo(map);

        if (onClick) {
            marker.on('click', onClick);
        }

        if (onMouseOver) {
            marker.on('mouseover', onMouseOver);
        }

        if (onMouseOut) {
            marker.on('mouseout', onMouseOut);
        }

        // const crossIcon = marker.getElement()?.querySelector('.cross-icon');
        // if (crossIcon) {
        //     crossIcon.addEventListener('click', (e) => {
        //         e.stopPropagation();
        //         onCrossClick();
        //     });
        // }

        return () => {
            marker.remove();
        };
    }, [position, imageUrl, onClick, onMouseOver, onMouseOut, map]);

    return null;
};


const MapComp: React.FC<MapCompProps> = ({coordinates, zoom, address, isAddingMarker, sendAddress, cleanMarker, handleCleanFalse, handleMarkerPosition, onMarkerClick, active}) => {
    const [loading, setLoading] = useState(false);
    const [satellite, setSatellite] = useState(false);
    const [markerPosition, setMarkerPosition] = useState<[number, number] | null>(null);
    const [hoveredMarkId, setHoveredMarkId] = useState<number | null>(null);
    const [crossShow, setCrossShow] = useState(false);
    const [filteredMarks, setFilteredMarks] = useState<Mark[]>([]);
    const dispatch =  useAppDispatch()

    const { marks, status, error } = useSelector((state: RootState) => state.marks);
    const user = useSelector((state: RootState) => state.auth.user);

    const handleCrossClick = (markId: number) => {
        // Ваш диспетчер для удаления или другой логики
        console.log('Клик по крестику на метке с id:', markId);
        setFilteredMarks((prevMarks) => prevMarks.filter((mark) => mark.id !== markId));
        dispatch(deleteMark(markId));
    };

    const handleCrossShow = () => {
        setCrossShow(true);
    }

    const handleCrossHide = () => {
        setCrossShow(false)
    }

    const getMarks = async () => {
        dispatch(fetchMarks())
    }

    useEffect(() => {
        getMarks();
    }, [])

    useEffect(() => {
        console.log(marks)
    }, [marks])

    useEffect(() => {
        if (!isAddingMarker) {
            setMarkerPosition(null);
        }
    }, [isAddingMarker])

    useEffect(() => {
        if (cleanMarker === true) {
            setMarkerPosition(null);
            handleCleanFalse()
        }
    }, [cleanMarker, markerPosition]);

    const map = useMapEvents({
        click: async (e) => {
            if (isAddingMarker) {
                setLoading(true); // Начало загрузки
                const { lat, lng } = e.latlng;
                setMarkerPosition([lat, lng]);
                const provider = new OpenStreetMapProvider();
                try {
                    const results = await provider.search({ query: `${lat}, ${lng}` });
                    if (results.length > 0) {
                        sendAddress(results[0].label);
                    }
                } catch (error) {
                    console.error(error);
                } finally {
                    setLoading(false); // Завершение загрузки
                }
            }
        },
    });

    useEffect(() => {
        if (address) {
            setLoading(true); // Начало загрузки
            const provider = new OpenStreetMapProvider();
            provider.search({ query: address }).then((results) => {
                if (results.length > 0) {
                    const { x, y } = results[0];
                    setMarkerPosition([y, x]);
                    map.setView(new L.LatLng(y, x), 20);
                }
            }).finally(() => {
                setLoading(false); // Завершение загрузки
            });
        }
    }, [address, map, zoom]);

    useEffect(() => {
        handleMarkerPosition(markerPosition);
    }, [markerPosition])

    const handleMarkerClick = (mark: Mark) => {
        onMarkerClick(mark);
    }

    useEffect(() => {
            if (active === 'all') {
                setFilteredMarks(marks);
            } else {
                if (user) {
                    setFilteredMarks(marks.filter(mark => mark.created_by === user.id));
                }
            }
    }, [active, user, marks]);

    return (
        <>
            {loading && <div>Загрузка...</div>}
            <LayersControl position="topright">
                <BaseLayer checked={!satellite} name="Обычный вид">
                    <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                </BaseLayer>
                <BaseLayer checked={satellite} name="Спутниковый вид">
                    <TileLayer
                        url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                        attribution="&copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community"
                    />
                </BaseLayer>
            </LayersControl>
            {markerPosition && (
                <Marker position={markerPosition} icon={customMarkerIcon}>
                    <Popup>{address}</Popup>
                </Marker>
            )}
            {
                status === 'succeeded' && (
                    filteredMarks.map((mark: Mark, index) => (
                        <CustomMarker
                            key={index}
                            // crossShow={hoveredMarkId === mark.id && user && mark.created_by === user.id}
                            onMouseOver={() => setHoveredMarkId(mark.id)}
                            onMouseOut={() => setHoveredMarkId(null)}
                            onClick={() => handleMarkerClick(mark)}
                            // onCrossClick={() => handleCrossClick(mark.id)}
                            position={mark.address}
                            imageUrl={mark.avatar}
                        />
                    ))
                )
            }
        </>
    );
};

export default MapComp;
