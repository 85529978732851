import React, {ReactNode} from 'react';
import MainHeader from "../headers/MainHeader";

interface MainLayoutProps {
    children: ReactNode;
}

const MainLayout: React.FC<MainLayoutProps> = ({children}) => {
    return (
        <div className={'main_layout'}>
            <MainHeader></MainHeader>
            <main>{children}</main>
        </div>
    );
};

export default MainLayout;