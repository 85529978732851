import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../store/authSlice';
import { RootState, AppDispatch } from '../../store/store';
import styles from './LoginPopup.module.scss'; // Импортируем стили

interface LoginPopupProps {
    onClose: () => void;
    onSuccess: () => void;
}

const LoginPopup: React.FC<LoginPopupProps> = ({ onClose, onSuccess }) => {
    const dispatch: AppDispatch = useDispatch();
    const authStatus = useSelector((state: RootState) => state.auth.status);
    const authError = useSelector((state: RootState) => state.auth.error);

    const [loginData, setLoginData] = useState({ username: '', password: '' });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setLoginData({ ...loginData, [name]: value });
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        dispatch(login(loginData));
    };

    useEffect(() => {
        if (authStatus === 'succeeded') {
            onSuccess();
        }
    }, [authStatus, onSuccess])

    return (
        <div className={styles.popupOverlay} onClick={onClose}>
            <div className={styles.popupContent} onClick={(e) => e.stopPropagation()}>
                <button className={styles.closeButton} onClick={onClose}>
                    <img src='/images/closeICON.svg' alt='close icon svg'/>
                </button>
                <h2>Требуется вход</h2>
                <p>Чтобы иметь возможность указать двор, вам необходимо ввести ваши данные с портала <a href="https://sakla.ru">sakla.ru</a></p>
                <form onSubmit={handleSubmit}>
                        <input type="text" name="username" value={loginData.username} onChange={handleChange} required />
                        <input type="password" name="password" value={loginData.password} onChange={handleChange} required />
                    <button type="submit" disabled={authStatus === 'loading'}>Войти</button>
                    {authStatus === 'failed' && <p className={styles.error}>{authError}</p>}
                </form>
                <p>Нет аккаунта? <a href="https://xn--c1abcnbeooeh4l.xn--p1ai/registration">Зарегистрироваться</a></p>
            </div>
        </div>
    );
};

export default LoginPopup;
