import React, { useEffect, useState, useRef } from 'react';
import {MapContainer, TileLayer, useMap, Polyline, Polygon, CircleMarker, useMapEvents} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import styles from './ExampleComponent.module.css';
//@ts-ignore
import * as d3 from 'd3';

interface Point {
    lat: number;
    lng: number;
}

interface Shape {
    points: Point[];
    type: string;
}

const SNAP_DISTANCE = 10; // Радиус притягивания в пикселях

const getColor = (object: any) => {
    switch (object?.type) {
        case 'Проезжая часть':
            return 'red';
        case 'Игровая зона':
            return 'green';
        case 'Выход с игровой площадки':
            return 'rgba(138,23,217,0.6)';
        case 'Парковка машин':
            return '#FFCC00';
        default:
            return 'black';
    }
};

interface EditingMapProps {
    selectedObject: any | null;
    onConfirm: (points: any) => void;
}

export const EditingMap: React.FC<EditingMapProps> = ({ selectedObject, onConfirm }) => {
    const [points, setPoints] = useState<Point[]>(selectedObject?.shape?.points);
    const [color, setColor] = useState<string>(getColor(selectedObject?.shape));
    const [draggingIndex, setDraggingIndex] = useState<number | null>(null);
    const [confirmMenuPosition, setConfirmMenuPosition] = useState<{ x: number; y: number } | null>(null);

    const map = useMap();

    useEffect(() => {
        if (selectedObject?.shape?.points) {
            setPoints(selectedObject.shape.points);
            setColor(getColor(selectedObject.shape));
        }
    }, [selectedObject]);

    const isClosedShape = () => {
        return (
            points.length > 1 &&
            points[0].lat === points[points.length - 1].lat &&
            points[0].lng === points[points.length - 1].lng
        );
    };

    const updatePoints = (index: number, newPoint: Point) => {
        const updatedPoints = [...points];

        if (isClosedShape()) {
            if (index === 0 || index === points.length - 1) {
                updatedPoints[0] = newPoint;
                updatedPoints[updatedPoints.length - 1] = newPoint;
            } else {
                updatedPoints[index] = newPoint;
            }
        } else {
            updatedPoints[index] = newPoint;
        }

        setPoints(updatedPoints);
    };

    const showConfirmMenu = (point: Point) => {
        const layerPoint = map.latLngToLayerPoint(point);
        const containerPoint = map.layerPointToContainerPoint(layerPoint);
        setConfirmMenuPosition({ x: containerPoint.x, y: containerPoint.y });
    };

    useMapEvents({
        mousemove: (event) => {
            if (draggingIndex !== null) {
                updatePoints(draggingIndex, event.latlng);
            }
        },
        mouseup: () => {
            if (draggingIndex !== null) {
                showConfirmMenu(points[draggingIndex]);
                setDraggingIndex(null);
                map.dragging.enable(); // Включаем перемещение карты после завершения перетаскивания точки
            }
        },
    });

    const handleMouseDown = (index: number) => {
        setDraggingIndex(index);
        map.dragging.disable();
    };

    const handleMouseMove = (event: L.LeafletMouseEvent) => {
        if (draggingIndex !== null) {
            const updatedPoints = [...points];
            updatedPoints[draggingIndex] = event.latlng; // Обновляем позицию точки
            setPoints(updatedPoints);
        }
    };

    const handleMouseUp = () => {
        setDraggingIndex(null); // Завершаем перетаскивание
    };

    const confirmShape = () => {
        setConfirmMenuPosition(null); // Скрываем меню после подтверждения
        console.log('points', points);
        onConfirm({type: selectedObject.shape.type, points: points, index: selectedObject.index}); // Отправляем данные о новом положении точек в родительский компонент
        setPoints([]);
    };

    return (
        points && (
                <>
                    <Polyline key={color} positions={points} color={color} />
                    {points.map((point, index) => (
                        <CircleMarker
                            key={`${color}-${index}`}
                            center={point}
                            radius={SNAP_DISTANCE}
                            color={color}
                            fillColor={color}
                            fillOpacity={0.2}
                            eventHandlers={{
                                mousedown: () => handleMouseDown(index),
                            }}
                        />
                    ))}
                    {confirmMenuPosition && (
                        <div
                            className="context-menu"
                            style={{
                                position: 'absolute',
                                top: confirmMenuPosition.y,
                                left: confirmMenuPosition.x,
                                zIndex: 1000,
                            }}
                        >
                            <div className="context-menu-option" onClick={confirmShape}>
                                <img src="/images/confirm.svg" alt=""/>
                                <span>Подтвердить</span>
                            </div>
                        </div>
                    )}
                </>
            )
    );
};

export const DrawingMap: React.FC<{ selectedObject: string | null, onDrawEnd: (shape: Shape) => void }> = ({ onDrawEnd, selectedObject }) => {
    console.log('SELECTED OBJECT', selectedObject)

    const [points, setPoints] = useState<Point[]>([]);
    const [tempPoint, setTempPoint] = useState<Point | null>(null); // Текущая позиция курсора
    const map = useMap();

    // Функция для вычисления расстояния между двумя точками в пикселях
    const getPixelDistance = (p1: L.LatLng, p2: L.LatLng) => {
        const point1 = map.latLngToContainerPoint(p1);
        const point2 = map.latLngToContainerPoint(p2);
        return point1.distanceTo(point2);
    };

    const handleClick = (e: L.LeafletMouseEvent) => {

        if (selectedObject === "Арка" || selectedObject === "Подъезд" || selectedObject === 'Выход с игровой площадки') {
            const newShape = { points: [{ lat: e.latlng.lat, lng: e.latlng.lng }], type: selectedObject! };
            onDrawEnd(newShape);
            setPoints([]);  // Не продолжаем рисовать линию
            return;
        }

        // Проверяем, достаточно ли близко текущая точка к первой точке
        if (points.length > 0) {
            const firstPoint = L.latLng(points[0].lat, points[0].lng);
            const currentPoint = L.latLng(e.latlng.lat, e.latlng.lng);

            if (getPixelDistance(firstPoint, currentPoint) < SNAP_DISTANCE) {
                setPoints([...points, { lat: points[0].lat, lng: points[0].lng }]); // Замыкаем фигуру
                const newShape = { points: [...points, { lat: points[0].lat, lng: points[0].lng }], type: selectedObject! };
                onDrawEnd(newShape);
                setPoints([]);
                setTempPoint(null);
                return;
            }
        }

        const newPoint = { lat: e.latlng.lat, lng: e.latlng.lng };
        setPoints([...points, newPoint]);
        setTempPoint(null); // После клика сбрасываем временную точку
    };

    const handleRightClick = () => {
        if (points.length > 0) {
            const newShape = { points, type: selectedObject! };
            onDrawEnd(newShape);
            setPoints([]);
            setTempPoint(null);
        }
    };

    const handleMouseMove = (e: L.LeafletMouseEvent) => {
        if (points.length > 0) {
            const newTempPoint = { lat: e.latlng.lat, lng: e.latlng.lng };
            setTempPoint(newTempPoint);
        }
    };

    useEffect(() => {
        console.log(selectedObject)
        if (selectedObject) {
            map.on('click', handleClick);
            map.on('contextmenu', handleRightClick); // Правый клик завершает рисование
            map.on('mousemove', handleMouseMove); // Следим за движением мыши

            return () => {
                map.off('click', handleClick);
                map.off('contextmenu', handleRightClick);
                map.off('mousemove', handleMouseMove);
            };
        }
    }, [points, selectedObject]);

    const isPolygonClosed = points.length > 2 && points[0].lat === points[points.length - 1].lat && points[0].lng === points[points.length - 1].lng;

    const getColor = () => {
        switch (selectedObject) {
            case 'Проезжая часть':
                return 'red';
            case 'Игровая зона':
                return 'green';
            case 'Парковка машин':
                return '#FFCC00';
            default:
                return 'black';
        }
    };

    return (
        <>
            {/* Рисуем линию по точкам */}
            <Polyline positions={points} color={getColor()} />
            {/* Добавляем временную линию до курсора */}
            {tempPoint && points.length > 0 && (
                <Polyline positions={[...points, tempPoint]} color={getColor()} dashArray="5, 10" />
            )}
            {/* Если область замкнута, рисуем ее как многоугольник */}
            {isPolygonClosed && (
                <Polygon positions={points} color={getColor()} fillColor="rgba(0, 0, 255, 0.3)" />
            )}
            {/* Подсвечиваем первую точку для визуализации притягивания */}
            {points.map (point => (
                <CircleMarker
                center={point}
                radius={SNAP_DISTANCE}
                color={getColor()}
                fillColor={getColor()}
                fillOpacity={0.2}
                />
            ))}
        </>
    );
};

const Example: React.FC = () => {
    const [shapes, setShapes] = useState<Shape[]>([]);
    const [showExample, setShowExample] = useState<boolean>(true);

    const handleDrawEnd = (newShape: Shape) => {
        setShapes([...shapes, newShape]);
    };

    return (
        <div className={styles["popup-background"]}>
        </div>
    );
};

export default Example;
