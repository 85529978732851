import React, {useEffect} from 'react';
import { BrowserRouter, Navigate, Route, Routes, useNavigate } from "react-router-dom";
import 'leaflet/dist/leaflet.css';
import Main from "./Pages/Main";
import MainLayout from "./Components/layouts/MainLayout";
import InstructionLayout from "./Components/layouts/InstructionLayout";
import Instruction from "./Pages/Instruction/Instruction";

function App() {

  return (
      <BrowserRouter>
          <Routes>
              <Route
                path="/"
                element={
                  <MainLayout>
                      <Main/>
                  </MainLayout>
              }
              />
              <Route
                path="/instruction"
                element={
                  <InstructionLayout>
                      <Instruction></Instruction>
                  </InstructionLayout>
                }
              />
          </Routes>
      </BrowserRouter>
  );
}

export default App;
