import React from 'react';
import styles from './Tip.module.scss';

interface tipProps {
    icon: string | null;
    type: string | null;
    description: string | null;
    handleTipClose: () => void;
}

const Tip = ({icon, type, description, handleTipClose}: tipProps) => {

    if (!icon || !type || !description) return <></>

    return (
        <div className={styles.tip}>
            <div className={styles.tip_icon}>
                <img src={icon} alt=""/>
            </div>
            <div className={styles.tip_text}>
                <h2>{type}</h2>
                <p>{description}</p>
            </div>
            <div className={styles.tip_close}>
                <img src="/images/tipClose.svg" alt="" onClick={handleTipClose}/>
            </div>
        </div>
    );
};

export default Tip;