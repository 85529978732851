import React, {ReactNode} from 'react';
import InstructionHeader from "../headers/InstructionHeader";

interface InstructionLayoutProps {
    children: ReactNode;
}

const InstructionLayout: React.FC<InstructionLayoutProps> = ({children}) => {
    return (
        <div>
            <InstructionHeader></InstructionHeader>
            <main>{children}</main>
        </div>
    );
};

export default InstructionLayout;
