import React, { MouseEventHandler, useEffect, useRef, useState } from 'react';
import JustMap from "../JustMap";
import {useAppDispatch} from "../../store/hooks";
import {addMark} from "../../store/markSlice";
import {useSelector} from "react-redux";
import {RootState} from "../../store/store";
import L, {point} from "leaflet";
import {MapContainer, TileLayer} from "react-leaflet";
import {DrawingMap} from "../Example/ExampleComponent";
import * as d3 from "d3";
import useConstructorFunctions, {patterns} from '../ConsturctorFunctions';

interface ImageConstructorProps {
    isOpen: boolean;
    onClose: () => void;
    address: string;
    markerPosition: [number, number] | null;
}

interface PointOld {
    x: number;
    y: number;
}

interface selectedPoint {
    type: string;
    coordinates: PointOld;
}

interface ShapeOld {
    points: PointOld[];
    type: string;
    color: string;
    patternId: string;
    iconHref: string; // Добавляем путь к иконке
}

interface Point {
    lat: number;
    lng: number;
}

interface Shape {
    points: Point[];
    type: string;
}

const ImageConstructor: React.FC<ImageConstructorProps> = ({ isOpen, onClose, address, markerPosition }) => {

    const {
        mainImage, setMainImage,
        mainImageName, setMainImageName, thumbnails, setThumbnails,
        showMap, setShowMap, selectedThumbnail, setSelectedThumbnail,
        showObjectMenu, setShowObjectMenu, selectedObject, setSelectedObject,
        points, setPoints,
        shapes, setShapes,
        entrances, setEntrances,
        arches, setArches,
        exits, setExits,
        entrancePoint, setEntrancePoint,
        isDragging, setIsDragging,
        afterDragEnd, setAfterDragEnd,
        dispatch, svgRef,
        draggingPointIndex,
        editing, setEditing,
        moving, setMoving,
        contextMenuVisible, setContextMenuVisible,
        contextMenuPosition, setContextMenuPosition,
        showConfirmMenu, setShowConfirmMenu,
        confirmMenuPosition, setConfirmMenuPosition,
        selectedShape, setSelectedShape,
        selectedPoint, setSelectedPoint,
        currentMousePosition, setCurrentMousePosition,
        handleMouseMoveTo, getPointOnSVG,
        handleConfirmMenu, handleImageUpload,
        handleRemoveThumbnail, handleThumbnailClick,
        handleObjectMenuClick,
        handleContextMenuHide, handleContextMenu,
        addPoint, handleDeleteClick, handleEditClick,
        handlePointMouseDown, renderPoints,
        confirmShape, renderPathOld, renderShapes,
        convertSVGPointsToPoints, convertShapes,
        renderContextMenu, renderExits, renderArches,
        renderEntrances, placeSource
        // Другие состояния и методы...
    } = useConstructorFunctions();


    const user = useSelector((state: RootState) => state.auth.user);
    const [newShapes, setNewShapes] = useState<Shape[]>([]);

    const handleMapClick = () => {
        setShowMap(true);
        setMainImage(null);
        setSelectedThumbnail(null);
        updateShapes();
    };


    const handleDrawEnd = (newShape: Shape) => {
        setNewShapes([...newShapes, newShape]);
        setSelectedObject(null);
    };

    const mapRef = useRef<L.Map | null>(null);
    const svgLayerRef = useRef<L.SVG | null>(null);

    const [renderedShapes, setRenderedShapes] = useState<Set<number>>(new Set());

    const updateShapes = () => {
        if (mapRef.current && svgLayerRef.current) {
            const svg = d3.select(mapRef.current.getPanes().overlayPane).select('svg');
            const g = svg.select('g');

            g.selectAll('path').remove();

            const currentZoom = mapRef.current.getZoom();
            const baseIconSize = 32; // Базовый размер иконки
            const scaleFactor = currentZoom / 18;

            newShapes.forEach((shape, index) => {

                if (shape.type === 'Арка' || shape.type === 'Подъезд' || shape.type === 'Выход с игровой площадки') {
                    // Обработка только для иконки
                    const iconHref = shape.type === 'Арка' ? '/images/arch.svg' : shape.type === 'Подъезд' ? '/images/entance.svg' : '/images/playZone.svg';

                    const center = shape.points[0]; // В случае иконки у нас одна точка
                    const layerPoint = mapRef.current?.latLngToLayerPoint([center.lat, center.lng]);

                    let imageElement: d3.Selection<SVGImageElement, unknown, null, undefined> = g.select<SVGImageElement>(`#icon-${index}`);
                    if (imageElement.empty()) {
                        imageElement = g.append('image')
                            .attr('id', `icon-${index}`)
                            .attr('href', iconHref)
                            .attr('width', 32)
                            .attr('height', 32)
                            .attr('style', 'cursor: pointer; z-index: 1000; pointer-events: all')
                            .on('click', () => handleIconClick(shape, index));
                    }

                    const iconSize = baseIconSize * scaleFactor;

                    if (layerPoint) {
                        imageElement
                            .attr('x', layerPoint.x - iconSize / 2)
                            .attr('y', layerPoint.y - iconSize / 2)
                            .attr('width', iconSize)
                            .attr('height', iconSize);
                    }

                    return;  // Прерываем, чтобы не рисовать зону
                }

                const patternId = shape.type === 'Проезжая часть' ? 'roadPattern' :
                    shape.type === 'Игровая зона' ? 'playAreaPattern' :
                    shape.type === 'Парковка машин' ? 'parkingPattern' :
                        'exitPattern';

                const strokeColor = shape.type === 'Проезжая часть' ? 'red' :
                    shape.type === 'Игровая зона' ? 'green' :
                    shape.type === 'Парковка машин' ? '#FFCC00' : 'black'

                const iconHref = shape.type === 'Проезжая часть' ? '/images/road.svg' :
                    shape.type === 'Игровая зона' ? '/images/playZone.svg' :
                        shape.type === 'Парковка машин' ? '/images/parking.svg' : null;

                g.append('path')
                    .attr('d', renderPath(shape.points))
                    .attr('fill', isPolygonClosed(shape.points) ? `url(#${patternId})` : 'none') // Заполняем область, если она замкнута
                    .attr('stroke', `${strokeColor}`)
                    .attr('stroke-width', 2)
                    .attr('style', 'z-index: 2; pointer-events: none');

                if (iconHref && !renderedShapes.has(index)) {
                    // Рассчитываем центр фигуры
                    const center = calculateCenter(shape.points);
                    // @ts-ignore
                    const layerPoint = mapRef.current.latLngToLayerPoint([center.lat, center.lng]);

                    let imageElement = g.select(`#icon-${index}`);
                    if (imageElement.empty()) {
                        // @ts-ignore
                        imageElement = g.append('image')
                            .attr('id', `icon-${index}`)
                            .attr('href', iconHref)
                            .attr('width', 32)
                            .attr('height', 32)
                            .attr('style', 'cursor: pointer; z-index: 1000; pointer-events: all')
                            .on('click', () => handleIconClick(shape, index));
                    }

                    const iconSize = baseIconSize * scaleFactor;
                    imageElement
                        .attr('x', layerPoint.x - iconSize / 2)  // Центрируем по оси X
                        .attr('y', layerPoint.y - iconSize / 2)
                        .attr('style', 'cursor: pointer; z-index: 1000; pointer-events: all')
                        .attr('width', iconSize)
                        .attr('height', iconSize);

                    // @ts-ignore
                    imageElement.node().parentNode.appendChild(imageElement.node());
                }
            });
        }
    };

    // @ts-ignore
    const handleIconClick = (shape, index) => {

        console.log('dsdsd', shape);
    }

    // @ts-ignore
    const calculateCenter = (points) => {
        const n = points.length;
        // @ts-ignore
        const sum = points.reduce((acc, point) => ({
            lat: acc.lat + point.lat,
            lng: acc.lng + point.lng,
        }), { lat: 0, lng: 0 });

        const avgLat = sum.lat / n;
        const avgLng = sum.lng / n;

        return { lat: avgLat, lng: avgLng };
    };

    const renderPath = (points: Point[]) => {
        const line = d3.line<Point>()
            .x((d: { lat: number; lng: number; }) => mapRef.current?.latLngToLayerPoint([d.lat, d.lng]).x || 0)
            .y((d: { lat: number; lng: number; }) => mapRef.current?.latLngToLayerPoint([d.lat, d.lng]).y || 0);

        return line(points);
    };

    const isPolygonClosed = (points: Point[]) => {
        return points.length > 2 && points[0].lat === points[points.length - 1].lat && points[0].lng === points[points.length - 1].lng;
    };

    useEffect(() => {
        if (mapRef.current && !svgLayerRef.current) {
            svgLayerRef.current = L.svg().addTo(mapRef.current);

            const svg = d3.select(mapRef.current.getPanes().overlayPane).select('svg');
            svg.append('g').attr('class', 'leaflet-zoom-hide');

            const defs = svg.append('defs');  // Добавляем паттерны только один раз при монтировании
            defs.append('pattern')
                .attr('id', 'roadPattern')
                .attr('patternUnits', 'userSpaceOnUse')
                .attr('width', 5)
                .attr('height', 10)
                .attr('patternTransform', 'rotate(45)')
                .append('line')
                .attr('x1', 0)
                .attr('y1', 0)
                .attr('x2', 0)
                .attr('y2', 10)
                .attr('stroke', 'red')
                .attr('stroke-width', 2);

            defs.append('pattern')
                .attr('id', 'playAreaPattern')
                .attr('patternUnits', 'userSpaceOnUse')
                .attr('width', 8)
                .attr('height', 8)
                .append('rect')
                .attr('x', 0)
                .attr('y', 0)
                .attr('width', 10)
                .attr('height', 10)
                .attr('fill', 'rgba(112,219,110, 0.6)');

            defs.append('pattern')
                .attr('id', 'exitPattern')
                .attr('patternUnits', 'userSpaceOnUse')
                .attr('width', 10)
                .attr('height', 10)
                .append('rect')
                .attr('x', 0)
                .attr('y', 0)
                .attr('width', 10)
                .attr('height', 10)
                .attr('fill', 'rgba(143,43,217,0.6)');

            defs.append('pattern')
                .attr('id', 'parkingPattern')
                .attr('patternUnits', 'userSpaceOnUse')
                .attr('width', 5)
                .attr('height', 10)
                .attr('patternTransform', 'rotate(-45)')
                .append('line')
                .attr('x1', 0)
                .attr('y1', 0)
                .attr('x2', 0)
                .attr('y2', 10)
                .attr('stroke', '#FFCC00')
                .attr('stroke-width', 2);

        }

        if (mapRef.current) {
            mapRef.current.on('zoomend moveend', updateShapes);
        }

        updateShapes();

        return () => {
            if (mapRef.current) {
                mapRef.current.off('zoomend moveend', updateShapes);
            }
        };
    }, [newShapes]);

    useEffect(() => {
        setShapes({});
        setEntrances({});
        setArches({});
        setExits({});
    }, [markerPosition]);

    const handleAddMark = async () => {
        if (user) {

            const map: {
                arches: any[];
                entrances: any[];
                exits: any[];
                shapes: any[];
            } = {
                arches: [],
                entrances: [],
                exits: [],
                shapes: []
            };

            console.log('newshapes', newShapes)
            console.log('arches', arches);
            console.log('shapes', shapes);
            console.log('map', map);

            if (arches.map) {
                console.log(arches.map);
                map.arches = convertSVGPointsToPoints(arches.map, 'Арка')
            }
            if (entrances.map) {
                map.entrances = convertSVGPointsToPoints(entrances.map, 'Подъезд')
            }
            if (exits.map) {
                map.exits = convertSVGPointsToPoints(exits.map, 'Выход')
            }
            // if (shapes.map) {
                map.shapes = newShapes;
            //}

            const placesArray: {
                type_id: number;
                shortFileName: string | null;
                image: string;
                source: any;
            }[] = [];

            thumbnails.forEach((item, index) => {
                placesArray.push({
                    type_id: 1,
                    shortFileName: item.shortFileName,
                    image: item.imageSrc,
                    source: placeSource(item.shortFileName)
                })
            })

            console.log(map);

          await dispatch(addMark({
                name: address,
                created_by: user.id,
                address: markerPosition,
                source: map,
                places: placesArray
            }));

            onClose()

            // Перезагрузка страницы после выполнения dispatch
            window.location.reload()
        }
    }

    if (!isOpen) return null;

    return (
        <div className='popup_overlay' onClick={onClose}>
            <div className="popup_content" onClick={(e) => e.stopPropagation()}>
                <div className='popup_header'>
                    <div className='popup_header_first'>
                        <img src="/images/point.svg" alt="" />
                        <span>{address}</span>
                    </div>
                </div>
                <div className='popup_flex'>
                    {mainImage && (
                        <div className='popup_image'
                        >
                            <img src={mainImage?.imageSrc} alt="" />
                            <svg
                                ref={svgRef}
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                }}
                                onClick={addPoint}
                                onMouseMove={handleMouseMoveTo}
                            >
                                <defs>
                                    <pattern id="pattern1" patternUnits="userSpaceOnUse" width="5" height="10" patternTransform="rotate(45)">
                                        <line x1="0" y1="0" x2="0" y2="10" stroke="red" strokeWidth="2" style={{ pointerEvents: 'none' }} />
                                    </pattern>
                                    <pattern id="pattern2" patternUnits="userSpaceOnUse" width="10" height="10">
                                        <rect width="10" height="10" fill="rgba(0, 255, 0, 0.8)" style={{ pointerEvents: 'none' }} />
                                    </pattern>
                                    <pattern id="pattern3" patternUnits="userSpaceOnUse" width="10" height="10" patternTransform="rotate(-45)">
                                        <line x1="0" y1="0" x2="10" y2="10" stroke="#FFCC00" strokeWidth="2" style={{ pointerEvents: 'none' }}/>
                                    </pattern>
                                    <pattern id="pattern4" patternUnits="userSpaceOnUse" width="10" height="10">
                                        <rect width="10" height="10" fill="rgba(0, 0, 255, 0.8)" style={{ pointerEvents: 'none' }}/>
                                    </pattern>
                                    <pattern id="pattern5" patternUnits="userSpaceOnUse" width="10" height="10">
                                        <rect width="10" height="10" fill="rgba(112,219,110, 0.8)" style={{ pointerEvents: 'none' }}/>
                                    </pattern>
                                    <pattern id="pattern6" patternUnits="userSpaceOnUse" width="10" height="10">
                                        <rect width="10" height="10" fill="rgba(75, 0, 130, 0.8)" style={{ pointerEvents: 'none' }}/>
                                    </pattern>
                                </defs>
                                {renderShapes()}
                                {renderEntrances()}
                                {renderArches()}
                                {renderExits()}
                                {renderPoints()}
                                {points.length > 0 && currentMousePosition && (
                                    <path d={renderPathOld(points, currentMousePosition)} fill={`url(#${selectedObject ? patterns[selectedObject] : ''})`} stroke="black" strokeWidth="2" style={{ pointerEvents: 'none' }} />
                                )}
                                {entrancePoint && (selectedObject === 'Подъезд' || selectedObject === 'Арка' || selectedObject === 'Выход с игровой площадки') && (
                                    <image href={selectedObject === 'Арка' ? "/images/arch.svg" : selectedObject === 'Выход с игровой площадки' ? "/images/exit.svg" : "/images/entance.svg"} x={entrancePoint.x - 16} y={entrancePoint.y - 16} height="32px" width="32px" />
                                )}
                            </svg>
                            {(contextMenuVisible && renderContextMenu())}
                            {showConfirmMenu && (
                                <div className='context-menu' style={{left: confirmMenuPosition.x, top: confirmMenuPosition.y}}>
                                    <div className="context-menu-option" onClick={confirmShape}>
                                        <img src="/images/confirm.svg" alt=""/>
                                        <span>Завершить</span>
                                    </div>
                                </div>
                            )}
                            {points.length === 0 && !entrancePoint && (
                                <button className='addObjectButton' onClick={() => setShowObjectMenu(!showObjectMenu)}>
                                    <img src="/images/addButton.svg" alt="" />
                                    <span>Добавить объект</span>
                                </button>
                            )}
                            {showObjectMenu && (
                                <div className='objectMenu'>
                                    <div className='objectMenu_button' onClick={() => handleObjectMenuClick('Арка')}>
                                        <img src="/images/arch.svg" alt="" />
                                        <span>Арка</span>
                                    </div>
                                    <div className='objectMenu_button' onClick={() => handleObjectMenuClick('Игровая зона')}>
                                        <img src="/images/playZone.svg" alt="" />
                                        <span>Игровая зона</span>
                                    </div>
                                    <div className='objectMenu_button' onClick={() => handleObjectMenuClick('Подъезд')}>
                                        <img src="/images/entance.svg" alt="" />
                                        <span>Подъезд</span>
                                    </div>
                                    <div className='objectMenu_button' onClick={() => handleObjectMenuClick('Парковка машин')}>
                                        <img src="/images/parking.svg" alt="" />
                                        <span>Парковка</span>
                                    </div>
                                    <div className='objectMenu_button' onClick={() => handleObjectMenuClick('Выход с игровой площадки')}>
                                        <img src="/images/exit.svg" alt="" />
                                        <span>Выход с игровой площадки</span>
                                    </div>
                                    <div className='objectMenu_button' onClick={() => handleObjectMenuClick('Проезжая часть')}>
                                        <img src="/images/road.svg" alt="" />
                                        <span>Проезжая часть</span>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                        <div className='popup_image'
                             style={{ display: showMap ? 'block' : 'none' }}
                        >
                            <MapContainer
                                //@ts-ignore
                                center={markerPosition}
                                zoom={18}
                                style={{ height: '100%', width: '100%', zIndex: 2 }}
                                ref={mapRef}
                            >
                                <TileLayer
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                />
                                <DrawingMap selectedObject={selectedObject} onDrawEnd={handleDrawEnd} />
                            </MapContainer>
                                {entrancePoint && (selectedObject === 'Подъезд' || selectedObject === 'Арка' || selectedObject === 'Выход с игровой площадки') && (
                                    <image href={selectedObject === 'Арка' ? "/images/arch.svg" : selectedObject === 'Выход с игровой площадки' ? "/images/exit.svg" : "/images/entance.svg"} x={entrancePoint.x - 16} y={entrancePoint.y - 16} height="32px" width="32px" />
                                )}
                            {contextMenuVisible && renderContextMenu()}
                            {/*{points.length === 0 && !entrancePoint && (*/}
                                <button className='addObjectButton' onClick={() => setShowObjectMenu(!showObjectMenu)}>
                                    <img src="/images/addButton.svg" alt="" />
                                    <span>Добавить объект</span>
                                </button>
                             {/*)}*/}
                            {showObjectMenu && (
                                <div className='objectMenu'>
                                    <div className='objectMenu_button' onClick={() => handleObjectMenuClick('Арка')}>
                                        <img src="/images/arch.svg" alt="" />
                                        <span>Арка</span>
                                    </div>
                                    <div className='objectMenu_button' onClick={() => handleObjectMenuClick('Игровая зона')}>
                                        <img src="/images/playZone.svg" alt="" />
                                        <span>Игровая зона</span>
                                    </div>
                                    <div className='objectMenu_button' onClick={() => handleObjectMenuClick('Подъезд')}>
                                        <img src="/images/entance.svg" alt="" />
                                        <span>Подъезд</span>
                                    </div>
                                    <div className='objectMenu_button' onClick={() => handleObjectMenuClick('Парковка машин')}>
                                        <img src="/images/parking.svg" alt="" />
                                        <span>Парковка</span>
                                    </div>
                                    <div className='objectMenu_button' onClick={() => handleObjectMenuClick('Выход с игровой площадки')}>
                                        <img src="/images/exit.svg" alt="" />
                                        <span>Выход с игровой площадки</span>
                                    </div>
                                    <div className='objectMenu_button' onClick={() => handleObjectMenuClick('Проезжая часть')}>
                                        <img src="/images/road.svg" alt="" />
                                        <span>Проезжая часть</span>
                                    </div>
                                </div>
                            )}
                        </div>
                        {/*<div className='popup_image_input'>*/}
                        {/*    <div className='image_place'>*/}
                        {/*        <img src="/images/imagePlace.png" alt="" />*/}
                        {/*        <p>*/}
                        {/*            <span onClick={() => document.getElementById('mainImageInput')?.click()}>*/}
                        {/*                Нажмите*/}
                        {/*            </span>, чтобы загрузить изображение*/}
                        {/*        </p>*/}
                        {/*    </div>*/}
                        {/*    <input*/}
                        {/*        id="mainImageInput"*/}
                        {/*        type="file"*/}
                        {/*        accept="image/*"*/}
                        {/*        style={{ display: 'none' }}*/}
                        {/*        onChange={(e) => handleImageUpload(e)}*/}
                        {/*    />*/}
                        {/*</div>*/}
                    <div className='thumbnail_panel'>
                        <div className='thumbnail_panel_map' onClick={handleMapClick}>
                            <JustMap coordinates={markerPosition}></JustMap>
                        </div>
                        <div className='thumbnails'>
                            {thumbnails.map((thumbnail, index) => (
                                <div key={index} className='thumbnail'>
                                        <div className='thumbnail_image_container'
                                             style={{
                                                 border: selectedThumbnail === index ? '4px solid #37AFF2' : 'none',
                                                 borderRadius: '16px'
                                             }}
                                        >
                                            <img src={thumbnail.imageSrc} alt="" onClick={() => handleThumbnailClick(thumbnail, index)} />
                                            <img className='removeButton' src='/images/removeButton.svg' alt='' onClick={() => handleRemoveThumbnail(index)}/>
                                        </div>
                                </div>
                            ))}
                            <div className='thumbnail_placeholder'>
                                <input
                                    id={`thumbnailInput`}
                                    type="file"
                                    accept="image/*"
                                    style={{ display: 'none' }}
                                    onChange={(e) => handleImageUpload(e)}
                                />
                                <span onClick={() => document.getElementById(`thumbnailInput`)?.click()}>+</span>
                            </div>
                        </div>
                        <div className='thumbnail_panel_buttons'>
                            <div className='blue_button' onClick={handleAddMark}>Сохранить метку</div>
                            <div className='blue_button blue_button_white' onClick={onClose}>Отменить</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ImageConstructor;
