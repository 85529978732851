import React, {useEffect, useState} from 'react';
import { OpenStreetMapProvider } from 'leaflet-geosearch';

interface AddressSearchInputProps {
    onAddressSelect: (address: string) => void;
    formattedAddress: string;
    handleSetAddingMarker: () => void;
    handelShowPopup: () => void;
    handleAddressToPopup: (address: string) => void;
}

interface SearchResult {
    label: string;
}

const AddressSearchInput: React.FC<AddressSearchInputProps> = ({ onAddressSelect, formattedAddress, handleSetAddingMarker, handelShowPopup, handleAddressToPopup }) => {
    const [address, setAddress] = useState('');
    const [availableAddress, setAvailableAddress] = useState('');

    const handleAddressSelect = (result: SearchResult) => {
        if (result) {
            onAddressSelect(result.label);
        }
    };

    const provider = new OpenStreetMapProvider();

    const searchAddress = async (query: string) => {
        if (query.length < 3) return;
        const results = await provider.search({ query });
        handleAddressSelect(results[0] as SearchResult);
        if (results[0]) {
            setAvailableAddress(results[0].label);
            setAddress(results[0].label);
        }
    };

    useEffect(() => {
        if (formattedAddress.length > 0) {
            setAddress(formattedAddress);
            setAvailableAddress(formattedAddress);
        }
    }, [formattedAddress])

    useEffect(() => {
        handleAddressToPopup(address);
    }, [address]);

    const cleanAddress = () => {
        setAddress("");
        handleSetAddingMarker();
    }

    return (
        <div className='address_search_flex'>
            <div className='address_search_input_wrapper'>
                <input
                    className='address_search_input'
                    type="text"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                            searchAddress('Казань '+address);
                        }
                    }}
                    placeholder="Поиск улицы на карте"
                />
                {
                    (formattedAddress.length === 0 && address.length === 0) ?
                        <img src="/images/loupe.svg" alt=""/> :
                        <img src="/images/x.svg" alt="" style={{cursor: "pointer"}} onClick={cleanAddress}/>
                }
            </div>
            {
                (availableAddress.length > 0 && address.length > 0) && (
                    <div className='address_search_button' onClick={handelShowPopup}>
                        <img src="/images/galka.svg" alt=""/>
                    </div>
                )
            }
        </div>
    );
}

export default AddressSearchInput;