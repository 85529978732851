// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Tip_tip__h1jiX {
  z-index: 900;
  left: 32px;
  top: 32px;
  position: absolute;
  padding: 20px;
  display: flex;
  border-radius: 16px;
  column-gap: 14px;
  background-color: rgba(255, 255, 255, 0.8980392157);
}

.Tip_tip_icon__XL9GC img {
  width: 40px;
  height: 40px;
}

.Tip_tip_text__u8nPI {
  display: flex;
  flex-direction: column;
  row-gap: 6px;
}
.Tip_tip_text__u8nPI h2 {
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
}
.Tip_tip_text__u8nPI p {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
}

.Tip_tip_close__MxC4v img {
  cursor: pointer;
  width: 24px;
  height: 24px;
}`, "",{"version":3,"sources":["webpack://./src/Components/Tip/Tip.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,UAAA;EACA,SAAA;EACA,kBAAA;EACA,aAAA;EACA,aAAA;EACA,mBAAA;EACA,gBAAA;EACA,mDAAA;AACF;;AAGE;EACE,WAAA;EACA,YAAA;AAAJ;;AAIA;EACE,aAAA;EACA,sBAAA;EACA,YAAA;AADF;AAEE;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;AAAJ;AAGE;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;AADJ;;AAME;EACE,eAAA;EACA,WAAA;EACA,YAAA;AAHJ","sourcesContent":[".tip {\n  z-index: 900;\n  left: 32px;\n  top: 32px;\n  position: absolute;\n  padding: 20px;\n  display: flex;\n  border-radius: 16px;\n  column-gap: 14px;\n  background-color: #FFFFFFE5;\n}\n\n.tip_icon {\n  img {\n    width: 40px;\n    height: 40px;\n  }\n}\n\n.tip_text {\n  display: flex;\n  flex-direction: column;\n  row-gap: 6px;\n  h2 {\n    font-size: 20px;\n    font-weight: 500;\n    line-height: 24px;\n    text-align: left;\n  }\n\n  p {\n    font-size: 16px;\n    font-weight: 400;\n    line-height: 20px;\n    text-align: left;\n  }\n}\n\n.tip_close {\n  img {\n    cursor: pointer;\n    width: 24px;\n    height: 24px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tip": `Tip_tip__h1jiX`,
	"tip_icon": `Tip_tip_icon__XL9GC`,
	"tip_text": `Tip_tip_text__u8nPI`,
	"tip_close": `Tip_tip_close__MxC4v`
};
export default ___CSS_LOADER_EXPORT___;
