import React, {useEffect, useRef, useState} from 'react';
import {Mark, deleteMark, addMark, updateMark, updatePlace} from "../../store/markSlice";
import JustMap from "../JustMap";
import styles from "../LoginPopup/LoginPopup.module.scss";
import {useAppDispatch} from "../../store/hooks";
import {useSelector} from "react-redux";
import {RootState} from "../../store/store";
import Tip from "../Tip/Tip";
import PrintingComponent from "../PrintingComponent/PrintingComponent";
import Example, {DrawingMap, EditingMap} from "../Example/ExampleComponent";
import {MapContainer, TileLayer} from "react-leaflet";
import useConstructorFunctions, {patterns} from '../ConsturctorFunctions';
import L from "leaflet";
import * as d3 from "d3";

interface YardScreenProps {
    isOpen: boolean;
    onClose: () => void;
    mark: Mark | null;
}

export interface Point {
    x: number;
    y: number;
}

export interface PointNew {
    lat: number;
    lng: number;
}

export interface ShapeNew {
    points: PointNew[];
    type: string;
}

export interface Shape {
    points: Point[];
    type: string;
    color: string;
    patternId: string;
    iconHref: string; // Путь к иконке
}


const YardScreen: React.FC<YardScreenProps> = ({isOpen, onClose, mark}) => {

    const [mainImage, setMainImage] = useState<{ imageSrc: string, shortFileName: string } | null>(null);
    const [showMap, setShowMap] = useState<boolean>(true);
    const [selectedThumbnail, setSelectedThumbnail] = useState<number | null>(null);
    const [userMark, setUserMark] = useState<boolean>(false);
    const [editing, setEditing] = useState<boolean>(false);
    const [contextMenuVisible, setContextMenuVisible] = useState(false);
    const [contextMenuPosition, setContextMenuPosition] = useState({ x: 0, y: 0 });
    const [selectedShape, setSelectedShape] = useState<Shape | null>(null);
    const [selectedPoint, setSelectedPoint] = useState<selectedPoint | null>(null);
    const [points, setPoints] = useState<Point[]>([]);
    const [shapes, setShapes] = useState<{ [key: string]: Shape[] }>({});
    const [selectedObject, setSelectedObject] = useState<string | null>(null);
    const [afterDragEnd, setAfterDragEnd] = useState<boolean>(false);
    const [isDragging, setIsDragging] = useState<boolean>(false);
    const draggingPointIndex = useRef<number | null>(null);
    const [tipShow, setTipShow] = useState<boolean>(false);
    const [tipType, setTipType] = useState<string | null>(null);
    const [tipIcon, setTipIcon] = useState<string | null>(null);
    const [tipDescription, setTipDescription] = useState<string | null>(null);
    const [confirmMenuPosition, setConfirmMenuPosition] = useState({x: 0, y: 0});
    const [showConfirmMenu, setShowConfirmMenu] = useState<boolean>(false);
    const [showPrintingComp, setShowPrintingComp] = useState<boolean>(false);
    const [currentMousePosition, setCurrentMousePosition] = useState<{ x: number, y: number } | null>(null);
    const user = useSelector((state: RootState) => state.auth.user);
    const mapRef = useRef<L.Map | null>(null);
    const svgLayerRef = useRef<L.SVG | null>(null);
    const [newShapes, setNewShapes] = useState<ShapeNew[]>([]);
    const [mapMenuVisible, setMapMenuVisible] = useState(false);
    const [mapMenuPosition, setMapMenuPosition] = useState<{ x: number; y: number } | null>(null);
    const [zoom, setZoom] = useState(18);
    const [center, setCenter] =  useState<any>(mark?.address);
    const contextMenuRef = useRef(null);
    const dispatch =  useAppDispatch()

    useEffect(() => {
        if (isOpen) {
            setShapes({'map': mark?.source.shapes});
            setNewShapes(mark?.source.shapes)
            mark?.places.map(place => {
                const key = place.shortFileName
                setShapes(prevShapes => {
                    const newShapes = {...prevShapes};
                    if (!newShapes[key]) newShapes[key] = [];
                    newShapes[key].push(...place.source.shapes);
                    return newShapes;
                })
            })
        }
    }, [mark, isOpen])

    const handleOutsideClick = (event: any) => {
        //@ts-ignore
        if (mapMenuPosition && contextMenuRef.current && !contextMenuRef.current.contains(event.target)) {
            setMapMenuPosition(null);
        }
    };

    useEffect(() => {
        // Добавляем обработчик клика на документ при монтировании
        document.addEventListener('mousedown', handleOutsideClick);

        // Удаляем обработчик клика при размонтировании компонента
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [mapMenuPosition]);

    //@ts-ignore
    const handleOnConfirm = (points) => {
        const updatedShapes = [...newShapes];
        updatedShapes[points.index] = {
            ...updatedShapes[points.index], points: points.points
        }
        setNewShapes(updatedShapes);
    }

    // @ts-ignore
    const handleMapDelete = (shape) => {
        setNewShapes(newShapes.filter(newShape => newShape !== shape.shape));
        setMapMenuPosition(null);
    }

    useEffect(() => {
        if (newShapes.length > 0) {
            updateShapes();
            handleUpdateMark();
        }
    }, [newShapes]);

    const handleMouseMoveTo = (e: React.MouseEvent<SVGSVGElement>) => {
        const point = getPointOnSVG(e);
        setCurrentMousePosition(point);
    }

    const getPointOnSVG = (e: React.MouseEvent<SVGSVGElement>) => {
        const svg = svgRef.current;
        if (!svg) {
            return { x: 0, y: 0 }
        } else {
            const point = svg.createSVGPoint();
            point.x = e.clientX;
            point.y = e.clientY;
            const transformedPoint = point.matrixTransform(svg.getScreenCTM()?.inverse());
            return transformedPoint;
        }
    };


    useEffect(() => {
        console.log()
if (mapRef.current) {
    mapRef.current.on('moveend', () => setCenter(mapRef.current?.getCenter()))
}

    }, [mapRef.current])

    const updateShapes = () => {
        if (mapRef.current && svgLayerRef.current) {
            const svg = d3.select(mapRef.current.getPanes().overlayPane).select('svg');
            const g = svg.select('g');

            g.selectAll('path').remove();

            const currentZoom = mapRef.current.getZoom();
            setZoom(currentZoom);
            const baseIconSize = 32; // Базовый размер иконки
            const scaleFactor = currentZoom / 18;
            newShapes.forEach((shape: ShapeNew, index) => {

                if (shape.type === 'Арка' || shape.type === 'Подъезд' || shape.type === 'Выход с игровой площадки') {
                    const iconHref = shape.type === 'Арка' ? '/images/arch.svg' : shape.type === 'Подъезд' ? '/images/entance.svg' : '/images/playZone.svg';

                    const center = shape.points[0];
                    //@ts-ignore
                    const layerPoint = mapRef.current.latLngToLayerPoint([center.lat, center.lng]);

                    let imageElement: d3.Selection<SVGImageElement, unknown, null, undefined> = g.select<SVGImageElement>(`#icon-${index}`);
                    const iconSize = baseIconSize * scaleFactor;
                    const iconX = layerPoint.x - iconSize / 2;
                    const iconY = layerPoint.y - iconSize / 2;
                    if (imageElement.empty()) {
                        imageElement = g.append('image')
                            .attr('id', `icon-${index}`)
                            .attr('href', iconHref)
                            .attr('width', 32)
                            .attr('height', 32)
                            .attr('style', 'cursor: pointer; z-index: 1000; pointer-events: all')
                            .on('click', (event) => {
                                const center = shape.points[0];
                                //@ts-ignore
                                const containerPoint = mapRef.current.latLngToContainerPoint([center.lat, center.lng]);
                                handleMapMenu(event, index, shape, containerPoint.x, containerPoint.y);
                            })
                    }

                    if (layerPoint) {  // Проверка на undefined
                        const iconSize = baseIconSize * scaleFactor;
                        imageElement
                            .attr('x', iconX)
                            .attr('y', iconY)
                            .attr('width', iconSize)
                            .attr('height', iconSize);
                    }

                    return;
                }

                const patternId = shape.type === 'Проезжая часть' ? 'roadPattern' :
                    shape.type === 'Игровая зона' ? 'playAreaPattern' :
                        shape.type === 'Парковка машин' ? 'parkingPattern' :
                            'exitPattern';

                const strokeColor = shape.type === 'Проезжая часть' ? 'red' :
                    shape.type === 'Игровая зона' ? 'green' :
                        shape.type === 'Парковка машин' ? '#FFCC00' :
                            shape.type === 'Выход с игровой площадки' ? 'rgba(138,23,217,0.6)' : 'black'

                const iconHref = shape.type === 'Проезжая часть' ? '/images/road.svg' :
                    shape.type === 'Игровая зона' ? '/images/playZone.svg' :
                        shape.type === 'Парковка машин' ? '/images/parking.svg' :
                            shape.type === 'Выход с игровой площадки' ? '/images/exit.svg' : null;

                g.append('path')
                    .attr('d', renderPath(shape.points))
                    .attr('fill', isPolygonClosed(shape.points) ? `url(#${patternId})` : 'none') // Заполняем область, если она замкнута
                    .attr('stroke', `${strokeColor}`)
                    .attr('stroke-width', 2)
                    .attr('style', 'z-index: 2; pointer-events: none');

                if (iconHref) {
                    // Рассчитываем центр фигуры
                    const center = calculateCenter(shape.points);
                    // @ts-ignore
                    const layerPoint = mapRef.current.latLngToLayerPoint([center.lat, center.lng]);
                    const iconSize = baseIconSize * scaleFactor;
                    const iconX = layerPoint.x - iconSize / 2;
                    const iconY = layerPoint.y - iconSize / 2;
                    let imageElement = g.select(`#icon-${index}`);
                    if (imageElement.empty()) {
                        // @ts-ignore
                        imageElement = g.append('image')
                            .attr('id', `icon-${index}`)
                            .attr('href', iconHref)
                            .attr('width', 32)
                            .attr('height', 32)
                            .attr('style', 'cursor: pointer; z-index: 1000; pointer-events: all')
                            .on('click', (event) => {
                                const center = shape.points[0];
                                //@ts-ignore
                                const containerPoint = mapRef.current.latLngToContainerPoint([center.lat, center.lng]);
                                handleMapMenu(event, index, shape, containerPoint.x, containerPoint.y);
                            })
                    }

                    imageElement
                        .attr('x', iconX)  // Центрируем по оси X
                        .attr('y', iconY)
                        .attr('style', 'cursor: pointer; z-index: 1000; pointer-events: all')
                        .attr('width', iconSize)
                        .attr('height', iconSize);

                }
            });
        }
    };

    const isPolygonClosed = (points: PointNew[]) => {
        return points.length > 2 && points[0].lat === points[points.length - 1].lat && points[0].lng === points[points.length - 1].lng;
    };

    // @ts-ignore
    const handleMapEdit = (shape) => {
        setMapMenuPosition(null);
        console.log('dsdsd', shape);
        setSelectedObject(shape)
    }

    // const handleDeleteClick = () => {
    //     if (selectedShape) {
    //         const key = showMap ? 'map' : mainImage?.shortFileName || '';
    //         setShapes(prevShapes => {
    //             const newShapes = {...prevShapes}
    //             newShapes[key] = newShapes[key].filter(shape => shape !== selectedShape);
    //             return newShapes;
    //         })
    //     }
    // }

    // @ts-ignore
    const calculateCenter = (points) => {
        const n = points.length;
        // @ts-ignore
        const sum = points.reduce((acc, point) => ({
            lat: acc.lat + point.lat,
            lng: acc.lng + point.lng,
        }), { lat: 0, lng: 0 });

        const avgLat = sum.lat / n;
        const avgLng = sum.lng / n;

        return { lat: avgLat, lng: avgLng };
    };

    const renderPath = (points: PointNew[]) => {
        const line = d3.line<PointNew>()
            .x((d: { lat: number; lng: number; }) => mapRef.current?.latLngToLayerPoint([d.lat, d.lng]).x || 0)
            .y((d: { lat: number; lng: number; }) => mapRef.current?.latLngToLayerPoint([d.lat, d.lng]).y || 0);

        return line(points);
    };

    useEffect(() => {
        if (mapRef.current && !svgLayerRef.current) {
            svgLayerRef.current = L.svg().addTo(mapRef.current);
            console.log('after add', svgLayerRef)
            const svg = d3.select(mapRef.current.getPanes().overlayPane).select('svg');
            svg.append('g').attr('class', 'leaflet-zoom-hide');
            const defs = svg.append('defs');  // Добавляем паттерны только один раз при монтировании
            defs.append('pattern')
                .attr('id', 'roadPattern')
                .attr('patternUnits', 'userSpaceOnUse')
                .attr('width', 5)
                .attr('height', 10)
                .attr('patternTransform', 'rotate(45)')
                .append('line')
                .attr('x1', 0)
                .attr('y1', 0)
                .attr('x2', 0)
                .attr('y2', 10)
                .attr('stroke', 'red')
                .attr('stroke-width', 2);

            defs.append('pattern')
                .attr('id', 'playAreaPattern')
                .attr('patternUnits', 'userSpaceOnUse')
                .attr('width', 8)
                .attr('height', 8)
                .append('rect')
                .attr('x', 0)
                .attr('y', 0)
                .attr('width', 10)
                .attr('height', 10)
                .attr('fill', 'rgba(112,219,110, 0.6)');

            defs.append('pattern')
                .attr('id', 'exitPattern')
                .attr('patternUnits', 'userSpaceOnUse')
                .attr('width', 10)
                .attr('height', 10)
                .append('rect')
                .attr('x', 0)
                .attr('y', 0)
                .attr('width', 10)
                .attr('height', 10)
                .attr('fill', 'rgba(143,43,217,0.6)');

            defs.append('pattern')
                .attr('id', 'parkingPattern')
                .attr('patternUnits', 'userSpaceOnUse')
                .attr('width', 5)
                .attr('height', 10)
                .attr('patternTransform', 'rotate(-45)')
                .append('line')
                .attr('x1', 0)
                .attr('y1', 0)
                .attr('x2', 0)
                .attr('y2', 10)
                .attr('stroke', '#FFCC00')
                .attr('stroke-width', 2);

        }

        if (mapRef.current) {
            mapRef.current.on('zoomend moveend', updateShapes);
          //  setZoom()
        }

        updateShapes();

        return () => {
            if (mapRef.current) {
                mapRef.current.off('zoomend moveend', updateShapes);
            }
        };
    }, [newShapes, isOpen, mapRef.current]);

    const onPrint = () => {
        setShowPrintingComp(true);
    }

    const closePrintingComp = () => {
        setShowPrintingComp(false);
    }

    const handleClose = () => {
        onClose();
        setMainImage(null);
        setSelectedThumbnail(null);
        setTipShow(false);
        setTipType(null);
        setTipDescription(null);
        cancelShape();
        setMapMenuPosition(null)
        mark = null;
        setShowMap(true);
      //  mapRef.current = null;
        svgLayerRef.current = null;
        console.log('refs reset')
    };

    useEffect(() => {
        const handleMouseUp = (e: MouseEvent) => {
            if (isDragging) {
                setIsDragging(false);
                setAfterDragEnd(true);
            }
            const svg = svgRef.current;
            if (!svg) return;
            const point = svg.createSVGPoint();
            point.x = e.clientX;
            point.y = e.clientY;
            const transformedPoint = point.matrixTransform(svg.getScreenCTM()?.inverse());
            handleConfirmMenu({x: transformedPoint.x, y: transformedPoint.y})
        };

        const handleMouseMove = (e: MouseEvent) => {
            if (draggingPointIndex.current === null) return;
            setShowConfirmMenu(false);
            const svg = svgRef.current;
            if (!svg) return;
            const point = svg.createSVGPoint();
            point.x = e.clientX;
            point.y = e.clientY;
            const transformedPoint = point.matrixTransform(svg.getScreenCTM()?.inverse());
            setPoints(prevPoints => {
                const newPoints = [...prevPoints];
                newPoints[draggingPointIndex.current as number] = { x: transformedPoint.x, y: transformedPoint.y };
                return newPoints;
            });
            setIsDragging(true); // Set isDragging true during dragging
        };

        if (isDragging) {
            window.addEventListener('mousemove', handleMouseMove);
            window.addEventListener('mouseup', handleMouseUp);
        } else {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
        }

        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
        };
    }, [isDragging]);

    useEffect(() => {
        if (afterDragEnd) {
            const timer = setTimeout(() => setAfterDragEnd(false), 100);
            return () => clearTimeout(timer);
        }
    }, [afterDragEnd]);

    const confirmMenuTimerRef = useRef<number | null>(null);

    const handleConfirmMenu = (point: Point) => {
     //   console.log(point);
        setConfirmMenuPosition({
            x: point.x,
            y: point.y
        })

        setShowConfirmMenu(false);

        if (confirmMenuTimerRef.current !== null) {
            clearTimeout(confirmMenuTimerRef.current);
        }

        // Set a new timer
        confirmMenuTimerRef.current = window.setTimeout(() => {
            setShowConfirmMenu(true);
        }, 1000);
    }

    const handleDeleteMark = async () => {
        if (mark) {
            await dispatch(deleteMark(mark.id))
            window.location.reload()
        }
    }

    const handlePointMouseDown = (index: number) => (e: React.MouseEvent) => {
        e.stopPropagation();
        draggingPointIndex.current = index;
        setIsDragging(true);
    };

    const renderPoints = () => {
        return points.map((point, index) => (
            <circle key={index} cx={point.x} cy={point.y} r="8" style={{ cursor: 'pointer', pointerEvents: 'all' }} fill="red" onMouseDown={handlePointMouseDown(index)} />
        ));
    };

    const cancelShape = () => {
        setPoints([]);
        // setEntrancePoint(null);
        setShowConfirmMenu(false);
        setEditing(false);
        setSelectedShape(null);
        setSelectedObject(null);
    };

    const confirmShape = () => {
        if (points.length >= 3 && selectedObject && selectedObject !== 'Арка' && selectedObject !== 'Подъезд' && selectedObject !== 'Выход с игровой площадки') {
            const key = showMap ? 'map' : mainImage?.shortFileName || '';
                setShapes(prevShapes => {
                    const newShapes = { ...prevShapes };
                    if (newShapes[key]) {
                        newShapes[key] = newShapes[key].map(shape =>
                            shape === selectedShape ? { ...shape, points } : shape
                        );
                    }
                    return newShapes;
                });
            }
            setPoints([]);
            setSelectedObject(null);
            setSelectedShape(null);
            setShowConfirmMenu(false);
            setEditing(false);
    };

    // useEffect(() => {
    //     if (showMap) {
    //         handleUpdateMark()
    //     } else {
    //         handleUpdatePlaces()
    //     }
    // }, [shapes])

    const handleEditClick = () => {
        if (selectedShape) {
            setEditing(true);
            setPoints(selectedShape.points);
            setSelectedObject(selectedShape.type)
            renderPoints()
        }
        setContextMenuVisible(false);
    };

    const handleDeleteClick = () => {
        if (selectedShape) {
            const key = showMap ? 'map' : mainImage?.shortFileName || '';
            setShapes(prevShapes => {
                const newShapes = {...prevShapes}
                newShapes[key] = newShapes[key].filter(shape => shape !== selectedShape);
                return newShapes;
            })
        }
    }
    interface selectedPoint {
        type: string;
        coordinates: Point;
    }

    interface IHandleContext {
        event: any;
        shape?: Shape | null;
        point?: selectedPoint | null;
    }

// @ts-ignore
    const handleContextMenu = (event, shape, point): IHandleContext => {
            event.preventDefault();
            if (userMark && points.length === 0) {
                if (shape) {
                    setContextMenuPosition({
                        // @ts-ignore
                        x: (shape.points.reduce((acc, point) => acc + point.x, 0) / shape.points.length) + 15,
                        // @ts-ignore
                        y: (shape.points.reduce((acc, point) => acc + point.y, 0) / shape.points.length) - 18
                    });
                    setContextMenuVisible(true);
                    setSelectedShape(shape);
                } else if (point) {
                    setContextMenuPosition({
                        x: point.coordinates.x - 16,
                        y: point.coordinates.y - 16
                    })
                    setContextMenuVisible(true);
                    setSelectedPoint(point);
                }
            }
    }

    const [editingShape, setEditingShape] = useState<any>();

    //@ts-ignore
    const handleMapMenu = (event, index, shape, x, y) => {
        event.preventDefault();
        if (mapRef.current) {
            setEditingShape({shape, index});
            setMapMenuPosition({ x, y });
        } else {
            return
        }
    }

    const handleContextMenuHide = () => {
        setContextMenuVisible(false);
    }

    // @ts-ignore
    const handleTipShow = (shape, point) => {
        if (!editing) {
            if (shape) {
                setTipType(shape.type)
                setTipIcon(shape.iconHref);
                if (shape.type === 'Проезжая часть') {
                    setTipDescription('На проезжей части играть нельзя!')
                } else if (shape.type === 'Парковка машин') {
                    setTipDescription('Водитель, выезжая с парковки, может не заметить ребенка. Будь осторожен!');
                } else if (shape.type === 'Игровая зона') {
                    setTipDescription('Играй только в отведенных для игр местах. Здесь ты в безопасности.')
                }
                setTipShow(true);
            } else if (point) {
                if (point.type === 'Подъезд') {
                    setTipType('Подъезд')
                    setTipIcon('/images/entance.svg');
                    setTipDescription('Выходя из подъезда посмотри по сторонам, и только потом продолжай движение!');
                } else if (point.type === 'Арка') {
                    setTipType('Арка')
                    setTipIcon('/images/arch.svg');
                    setTipDescription('Из арки могут выехать автомобили. Будь внимателен!');
                } else if (point.type === 'Выход') {
                    setTipType('Выход с игровой площадки')
                    setTipIcon('/images/exit.svg');
                    setTipDescription('Будь внимателен при выходе с игровой площадки! Водители проезжающих машин могут тебя не заметить.');
                }
                setTipShow(true);
            }
        }
    }

    const handleTipClose = () => {
        setTipShow(false)
    }

    const handleMapClick = () => {
        setShowMap(true);
        setMainImage(null);
        setSelectedThumbnail(null);
        setTipShow(false);
        setTipType(null);
        setTipDescription(null);
        setEditing(false);
        cancelShape()
    };

    const handleThumbnailClick = (imageSrc: string | null, shortFileName: string, index: number) => {
        if (imageSrc) {
            setMainImage({ imageSrc: imageSrc, shortFileName: shortFileName });
            setShowMap(false);
            setSelectedThumbnail(index);
            setTipShow(false);
            setTipType(null);
            setTipDescription(null);
            setEditing(false);
            cancelShape()
        }
    };

    const svgRef = useRef<SVGSVGElement | null>(null);

    const renderPathOld = (points: Point[], currentMousePosition?: Point | null) => {
        if (points.length === 0) return '';
        const pathPoints = [...points];
        if (currentMousePosition && !editing) {
            pathPoints.push(currentMousePosition);
        }
        const d = pathPoints.map((point, index) => `${index === 0 ? 'M' : 'L'} ${point.x} ${point.y}`).join(' ');
        return `${d} Z`;
    };

    const addPoint = (e: React.MouseEvent<SVGSVGElement>) => {
        console.log(afterDragEnd)
        if (!selectedObject || afterDragEnd) return;
        const point = getPointOnSVG(e);
        // if (selectedObject === 'Подъезд' || selectedObject === 'Арка' || selectedObject === 'Выход с игровой площадки') {
        //     setEntrancePoint(point);
        //     handleConfirmMenu(point);
        //     return;
        // }

        setPoints(prevPoints => {
            const newPoints = [...prevPoints, point];
            return newPoints;
        });
        setAfterDragEnd(false); // Reset afterDragEnd after successfully adding a point
    };

    const renderShapes = () => {
        const key = showMap ? 'map' : mainImage?.shortFileName || '';
        return (shapes[key] || []).map((shape, index) => (
            <g key={index}>
                <path
                    d={renderPathOld(shape.points)}
                    fill={`url(#${shape.patternId})`}
                    stroke={shape.color}
                    strokeWidth="2"
                />
                {shape.iconHref && (
                    <image
                        onMouseOver={(e) => handleContextMenu(e, shape, null)}
                        onTouchEnd={(e) => handleContextMenu(e, shape, null)}
                        onMouseOut={(e)=>handleContextMenuHide()}
                        onClick={(e) => handleTipShow(shape, null)}
                        href={shape.iconHref}
                        x={(shape.points.reduce((acc, point) => acc + point.x, 0) / shape.points.length) - 16}
                        y={(shape.points.reduce((acc, point) => acc + point.y, 0) / shape.points.length) - 16}
                        height="32px"
                        width="32px"
                    />
                )}
            </g>
        ));
    };

    const renderIcons = (points: Point[], iconHref: string) => {
        return points.map((point, index) => (
            <image
                onClick={(e) => handleTipShow(null, point)}
                key={index}
                href={iconHref}
                x={(point.x || 0) - 16}
                y={(point.y || 0) - 16}
                height="32px"
                width="32px"
            />
        ));
    };

    const renderContextMenu = () => (
        <div className="context-menu" style={{ top: contextMenuPosition.y, left: contextMenuPosition.x }}
             onMouseOver={(e) => setContextMenuVisible(true)}
             onMouseOut={() => setContextMenuVisible(false)}
        >
            <div className="context-menu-option" onClick={handleEditClick}>
                <img src="/images/pen.svg" alt=""/>
                <span>Редактировать</span>
            </div>
            <div className="context-menu-option" onClick={handleDeleteClick}>
                <img src="/images/trash.svg" alt=""/>
                <span>Удалить</span>
            </div>
            {/*<div className="context-menu-option" onClick={handleMoveClick}>*/}
            {/*    <img src="/images/cursor.svg" alt=""/>*/}
            {/*    <span>Перенести</span>*/}
            {/*</div>*/}
        </div>
    );

    useEffect(() => {
        if (user && mark) {
            if (mark.created_by === user.id) {
                setUserMark(true)
            } else {
                setUserMark(false)
            }
        }
    }, [mark, user])

    const placeSource = (shortFileName: string) => {
        const source: {
            arches: any[];
            entrances: any[];
            exits: any[];
            shapes: any[];
        } = {
            arches: [],
            entrances: [],
            exits: [],
            shapes: []
        };
        if (shapes[shortFileName]) {
            source.shapes = shapes[shortFileName];
        }
        return source;
    }

    const handleUpdatePlaces = async () => {
        if (mark) {
            const placesArray: {
                id: number;
                source: any;
            }[] = [];

            mark?.places.forEach((item) => {
                placesArray.push({
                    id: item.id,
                    source: placeSource(item.shortFileName)
                })
            })
            for (const place of placesArray) {
                await dispatch(updatePlace({
                    data: {
                        source: place.source,
                    },
                    id: place.id
                }));
            }
        }
    }

    const handleUpdateMark = async () => {
        if (mark) {
            const map: {
                arches: any[];
                entrances: any[];
                exits: any[];
                shapes: any[];
            } = {
                arches: [],
                entrances: [],
                exits: [],
                shapes: []
            };

                map.shapes = newShapes;

                console.log(map.shapes)

            await dispatch(updateMark({
                data: {
                    source: map,
                },
                id: mark.id
            }));
        }
    }

    const [dimensions, setDimensions] = useState({ width: 800, height: 500 });

    const popupRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        const handleResize = () => {
            if (popupRef.current) {
                setDimensions({
                    width: popupRef.current.offsetWidth,
                    height: popupRef.current.offsetHeight,
                });
            }
        };

        const resizeObserver = new ResizeObserver(() => handleResize());
        if (popupRef.current) {
            resizeObserver.observe(popupRef.current);
        }

        handleResize();

        return () => {
            resizeObserver.disconnect();
        };
    }, []);

    if (!isOpen) return null;

    return (
        <>
            {//@ts-ignore
                <PrintingComponent isOpen={showPrintingComp} onClose={closePrintingComp} mark={mark} center={center} zoom={zoom} newShapes={newShapes}/>
            }
            <div className='popup_overlay' >
                <button className='closeButton' onClick={handleClose}>
                    <img src='/images/closeICON.svg' alt='close icon svg'/>
                </button>
                {mark && user && (
                    <div className="popup_content" >
                        <div className='popup_yard_header'>
                            <div className='popup_header'>
                                <div className='popup_header_first'>
                                    <img src="/images/point.svg" alt="" />
                                    <span>{mark.name}</span>
                                </div>
                                {userMark && (
                                    <div className='popup_buttons'>
                                        <div className='popup_button' onClick={onPrint}>
                                            <img src="/images/printer.svg" alt=""/>
                                            <span>Распечатать</span>
                                        </div>
                                        <div className='popup_button' onClick={handleDeleteMark}>
                                            <img src="/images/trashcan.svg" alt=""/>
                                            <span>Удалить метку</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className='popup_yard_header_avatar'>
                                <div className='avatar'>
                                    <img src={mark.avatar || '/images/stdavatar.svg'} alt=""/>
                                </div>
                                <div className='name_and_date'>
                                    <span className='name'>
                                        {mark.firstname} {mark.lastname}
                                    </span>
                                    <span className='date'>
                                        {mark.created_at}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className='popup_flex'>
                            {mainImage && (
                                <div className='popup_image'>
                                    <img src={mainImage.imageSrc} alt="" />
                                    <svg
                                        ref={svgRef}
                                        style={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            width: '100%',
                                            height: '100%',
                                        }}
                                        onMouseMove={handleMouseMoveTo}
                                        onClick={addPoint}
                                    >
                                                <defs>
                                                    <pattern id="pattern1" patternUnits="userSpaceOnUse" width="5" height="10" patternTransform="rotate(45)">
                                                        <line x1="0" y1="0" x2="0" y2="10" stroke="red" strokeWidth="2" style={{ pointerEvents: 'none' }}/>
                                                    </pattern>
                                                    <pattern id="pattern2" patternUnits="userSpaceOnUse" width="10" height="10">
                                                        <rect width="10" height="10" fill="rgba(0, 255, 0, 0.8)" />
                                                    </pattern>
                                                    <pattern id="pattern3" patternUnits="userSpaceOnUse" width="10" height="10" patternTransform="rotate(-45)">
                                                        <line x1="0" y1="0" x2="10" y2="10" stroke="#FFCC00" strokeWidth="2" style={{ pointerEvents: 'none' }}/>
                                                    </pattern>
                                                    <pattern id="pattern4" patternUnits="userSpaceOnUse" width="10" height="10">
                                                        <rect width="10" height="10" fill="rgba(0, 0, 255, 0.8)" style={{ pointerEvents: 'none' }}/>
                                                    </pattern>
                                                    <pattern id="pattern5" patternUnits="userSpaceOnUse" width="10" height="10">
                                                        <rect width="10" height="10" fill="rgba(112,219,110, 0.8)" style={{ pointerEvents: 'none' }}/>
                                                    </pattern>
                                                    <pattern id="pattern6" patternUnits="userSpaceOnUse" width="10" height="10">
                                                        <rect width="10" height="10" fill="rgba(75, 0, 130, 0.8)" style={{ pointerEvents: 'none' }}/>
                                                    </pattern>
                                                </defs>
                                                {renderShapes()}
                                                {/*{renderIcons(place.source.entrances, "/images/entance.svg")}*/}
                                                {/*{renderIcons(place.source.arches, "/images/arch.svg")}*/}
                                                {/*{renderIcons(place.source.exits, "/images/exit.svg")}*/}
                                                {renderPoints()}
                                                {points.length > 0 && currentMousePosition && (
                                                    <path d={renderPathOld(points, currentMousePosition)} fill={`url(#${selectedObject ? patterns[selectedObject] : ''})`} stroke="black" strokeWidth="2" style={{ pointerEvents: 'none' }} />
                                                )}
                                            </svg>
                                            {(contextMenuVisible && renderContextMenu())}
                                    {showConfirmMenu && (
                                        <div className='context-menu' style={{left: confirmMenuPosition.x, top: confirmMenuPosition.y}}>
                                            <div className="context-menu-option" onClick={confirmShape}>
                                                <img src="/images/confirm.svg" alt=""/>
                                                <span>Подтвердить</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                                <div
                                    className='popup_image'
                                    ref={popupRef}
                                    style={{ display: showMap ? 'block' : 'none' }}
                                >
                                    <MapContainer
                                        //@ts-ignore
                                        center={mark.address}
                                        zoom={18}
                                        style={{ height: '100%', width: '100%', zIndex: 2 }}
                                        ref={mapRef}
                                    >
                                        <TileLayer
                                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                        />
                                        <EditingMap selectedObject={selectedObject} onConfirm={handleOnConfirm}></EditingMap>
                                    </MapContainer>
                                    {(contextMenuVisible && renderContextMenu())}
                                    {
                                        mapMenuPosition && (
                                            <div
                                                className="context-menu"
                                                ref={contextMenuRef}
                                                style={{
                                                    position: 'absolute',
                                                    top: mapMenuPosition.y,
                                                    left: mapMenuPosition.x,
                                                    zIndex: 1000,
                                                }}
                                            >
                                                <div className="context-menu-option" onClick={() => { handleMapEdit(editingShape)}}>
                                                    <img src="/images/pen.svg" alt="Редактировать" />
                                                    <span>Редактировать</span>
                                                </div>
                                                <div className="context-menu-option" onClick={() => { handleMapDelete(editingShape) }}>
                                                    <img src="/images/trash.svg" alt="Удалить" />
                                                    <span>Удалить</span>
                                                </div>
                                            </div>
                                        )
                                    }
                                    {showConfirmMenu && (
                                        <div className='context-menu' style={{left: confirmMenuPosition.x, top: confirmMenuPosition.y}}>
                                            <div className="context-menu-option" onClick={confirmShape}>
                                                <img src="/images/confirm.svg" alt=""/>
                                                <span>Подтвердить</span>
                                            </div>
                                        </div>
                                    )}
                                    {tipShow && <Tip icon={tipIcon} type={tipType} description={tipDescription} handleTipClose={handleTipClose}/>}
                                </div>
                            {/*<div className='thumbnail_panel'>*/}
                                <div className='thumbnails_yard'>
                                    <div className='thumbnails_yard_block' onClick={handleMapClick}>
                                        <JustMap coordinates={mark.address}></JustMap>
                                    </div>
                                        {mark.places.map((place, index) => (
                                            <div className='thumbnails_yard_block'
                                                 key={place.id}
                                                 onClick={() => handleThumbnailClick(place.image, place.shortFileName, index)}
                                                 style={{
                                                     border: selectedThumbnail === index ? '4px solid #37AFF2' : 'none',
                                                     borderRadius: '16px'
                                                 }}
                                            >
                                                <img src={place.image} alt=""/>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                    </div>
                )}
            </div>

        </>
    );
};

export default YardScreen;
