import { configureStore } from '@reduxjs/toolkit';
import authReducer, {setUser} from './authSlice';
import markReducer from './markSlice';

const store = configureStore({
    reducer: {
        auth: authReducer,
        marks: markReducer
    }
});

const user = localStorage.getItem('user');
if (user) {
    console.log(user);
    store.dispatch(setUser(JSON.parse(user)));
}

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
