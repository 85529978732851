import React, { useEffect, useRef, useState } from 'react';
import styles from './PrintingComponent.module.scss';
import { fetchMarks, Mark } from "../../store/markSlice";
import { fetchMarkById } from "../../store/markSlice";
import { useAppDispatch } from "../../store/hooks";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import {Point, PointNew, Shape, ShapeNew} from "../YardScreen/YardScreen";
import { toPng } from 'html-to-image';
import jsPDF from 'jspdf';
import MapImage from "../MapImage/MapImage";
import L from 'leaflet';
import {MapContainer, TileLayer} from "react-leaflet";
import * as d3 from "d3";
import html2canvas from "html2canvas";

interface PrintingComponentProps {
    isOpen: boolean;
    onClose: () => void;
    mark: Mark | null;
    center: any;
    zoom: number;
    newShapes: any[]
}

const PrintingComponent: React.FC<PrintingComponentProps> = ({ isOpen, onClose, mark, center, zoom, newShapes }) => {
    const [isMapReady, setIsMapReady] = useState(false);
    const printRef = useRef<HTMLDivElement>(null);
    const [tileCount, setTileCount] = useState(0); // Счетчик тайлов
    const map = useRef<L.Map | null>(null);
    const svgLayerRef = useRef<L.SVG | null>(null);

    const handleTileLoad = () => {
        setTileCount((prev) => prev - 1); // Уменьшаем счетчик загруженных тайлов
        if (tileCount === 1) {
            setIsMapReady(true); // Когда все тайлы загружены
        }
    };

    const handleTileLoadStart = () => {
        setTileCount((prev) => prev + 1); // Увеличиваем счетчик на каждый загружаемый тайл
    };

    const handleClose = () => {
        onClose();
    };

    useEffect(() => {
        if (isMapReady) {
            // Здесь можно выполнять логику, которая зависит от готовности карты
            // Например, обновить слои или отрисовать фигуры
            updateShapes()
        }
    }, [isMapReady]);

    const handleMapLoad = () => {
        setIsMapReady(true); // Карта готова
    };


    // useEffect(() => {
    //     if (map.current && isOpen && mark && isMapReady) {
    //
    //         console.log(map.current)
    //         //@ts-ignore
    //         map.current.target.on("tileload", () => console.log('dsds'));
    //     }
    // }, [isOpen, map.current, mark]);

    useEffect(() => {
        if (isMapReady && isOpen) {
            setTimeout(() => {
                window.print();
            }, 1000); // Задержка в 1 секунду перед печатью
        }
    }, [isMapReady, isOpen]);

    const updateShapes = () => {
        if (map.current && svgLayerRef.current) {
            // @ts-ignore
            const svg = d3.select(map.current.target.getPanes().overlayPane).select('svg');
            const g = svg.select('g');

            g.selectAll('path').remove();
// @ts-ignore
            const currentZoom = map.current.target.getZoom();
            const baseIconSize = 32; // Базовый размер иконки
            const scaleFactor = currentZoom / 18;
            newShapes.forEach((shape: ShapeNew, index) => {

                if (shape.type === 'Арка' || shape.type === 'Подъезд' || shape.type === 'Выход с игровой площадки') {
                    const iconHref = shape.type === 'Арка' ? '/images/arch.svg' : shape.type === 'Подъезд' ? '/images/entance.svg' : '/images/playZone.svg';

                    const center = shape.points[0];
                    // @ts-ignore
                    const layerPoint = map.current.target?.latLngToLayerPoint([center.lat, center.lng]);

                    let imageElement: d3.Selection<SVGImageElement, unknown, null, undefined> = g.select<SVGImageElement>(`#icon-${index}`);
                    if (imageElement.empty()) {
                        imageElement = g.append('image')
                            .attr('id', `icon-${index}`)
                            .attr('href', iconHref)
                            .attr('width', 32)
                            .attr('height', 32)
                            .attr('style', 'cursor: pointer; z-index: 1000; pointer-events: all')
                    }
                    if (layerPoint) {  // Проверка на undefined
                        const iconSize = baseIconSize * scaleFactor;
                        imageElement
                            .attr('x', layerPoint.x - iconSize / 2)
                            .attr('y', layerPoint.y - iconSize / 2)
                            .attr('width', iconSize)
                            .attr('height', iconSize);
                    }

                    return;
                }

                const patternId = shape.type === 'Проезжая часть' ? 'roadPattern' :
                    shape.type === 'Игровая зона' ? 'playAreaPattern' :
                        shape.type === 'Парковка машин' ? 'parkingPattern' :
                            'exitPattern';

                const strokeColor = shape.type === 'Проезжая часть' ? 'red' :
                    shape.type === 'Игровая зона' ? 'green' :
                        shape.type === 'Парковка машин' ? '#FFCC00' :
                            shape.type === 'Выход с игровой площадки' ? 'rgba(138,23,217,0.6)' : 'black'

                const iconHref = shape.type === 'Проезжая часть' ? '/images/road.svg' :
                    shape.type === 'Игровая зона' ? '/images/playZone.svg' :
                        shape.type === 'Парковка машин' ? '/images/parking.svg' :
                            shape.type === 'Выход с игровой площадки' ? '/images/exit.svg' : null;

                g.append('path')
                    .attr('d', renderPath(shape.points))
                    .attr('fill', isPolygonClosed(shape.points) ? `url(#${patternId})` : 'none') // Заполняем область, если она замкнута
                    .attr('stroke', `${strokeColor}`)
                    .attr('stroke-width', 2)
                    .attr('style', 'z-index: 2; pointer-events: none');

                if (iconHref) {
                    // Рассчитываем центр фигуры
                    const center = calculateCenter(shape.points);
                    // @ts-ignore
                    const layerPoint = map.current.target.latLngToLayerPoint([center.lat, center.lng]);

                    let imageElement = g.select(`#icon-${index}`);
                    if (imageElement.empty()) {
                        // @ts-ignore
                        imageElement = g.append('image')
                            .attr('id', `icon-${index}`)
                            .attr('href', iconHref)
                            .attr('width', 32)
                            .attr('height', 32)
                            .attr('style', 'z-index: 1000')
                    }

                    const iconSize = baseIconSize * scaleFactor;
                    imageElement
                        .attr('x', layerPoint.x - iconSize / 2)  // Центрируем по оси X
                        .attr('y', layerPoint.y - iconSize / 2)
                        .attr('style', 'cursor: pointer; z-index: 1000; pointer-events: all')
                        .attr('width', iconSize)
                        .attr('height', iconSize);

                }
            });
        }
    };

    useEffect(() => {
        if (map.current && !svgLayerRef.current) {
            // @ts-ignore
            svgLayerRef.current = L.svg().addTo(map.current.target);
            // @ts-ignore
            const svg = d3.select(map.current.target.getPanes().overlayPane).select('svg');
            svg.append('g').attr('class', 'leaflet-zoom-hide');
            const defs = svg.append('defs');  // Добавляем паттерны только один раз при монтировании
            defs.append('pattern')
                .attr('id', 'roadPattern')
                .attr('patternUnits', 'userSpaceOnUse')
                .attr('width', 5)
                .attr('height', 10)
                .attr('patternTransform', 'rotate(45)')
                .append('line')
                .attr('x1', 0)
                .attr('y1', 0)
                .attr('x2', 0)
                .attr('y2', 10)
                .attr('stroke', 'red')
                .attr('stroke-width', 2);

            defs.append('pattern')
                .attr('id', 'playAreaPattern')
                .attr('patternUnits', 'userSpaceOnUse')
                .attr('width', 8)
                .attr('height', 8)
                .append('rect')
                .attr('x', 0)
                .attr('y', 0)
                .attr('width', 10)
                .attr('height', 10)
                .attr('fill', 'rgba(112,219,110, 0.6)');

            defs.append('pattern')
                .attr('id', 'exitPattern')
                .attr('patternUnits', 'userSpaceOnUse')
                .attr('width', 10)
                .attr('height', 10)
                .append('rect')
                .attr('x', 0)
                .attr('y', 0)
                .attr('width', 10)
                .attr('height', 10)
                .attr('fill', 'rgba(143,43,217,0.6)');

            defs.append('pattern')
                .attr('id', 'parkingPattern')
                .attr('patternUnits', 'userSpaceOnUse')
                .attr('width', 5)
                .attr('height', 10)
                .attr('patternTransform', 'rotate(-45)')
                .append('line')
                .attr('x1', 0)
                .attr('y1', 0)
                .attr('x2', 0)
                .attr('y2', 10)
                .attr('stroke', '#FFCC00')
                .attr('stroke-width', 2);

        }

        if (map.current) {
            // @ts-ignore
            map.current.target.on('zoomend moveend', updateShapes);
        }

        updateShapes();

        return () => {
            if (map.current) {
                // @ts-ignore
                map.current.target.off('zoomend moveend', updateShapes);
            }
        };
    }, [newShapes, isOpen, map.current]);

    const isPolygonClosed = (points: PointNew[]) => {
        return points.length > 2 && points[0].lat === points[points.length - 1].lat && points[0].lng === points[points.length - 1].lng;
    };

    // @ts-ignore
    const calculateCenter = (points) => {
        const n = points.length;
        // @ts-ignore
        const sum = points.reduce((acc, point) => ({
            lat: acc.lat + point.lat,
            lng: acc.lng + point.lng,
        }), { lat: 0, lng: 0 });

        const avgLat = sum.lat / n;
        const avgLng = sum.lng / n;

        return { lat: avgLat, lng: avgLng };
    };

    const renderPath = (points: PointNew[]) => {
        const line = d3.line<PointNew>()
            // @ts-ignore
            .x((d: { lat: number; lng: number; }) => map.current?.target.latLngToLayerPoint([d.lat, d.lng]).x || 0)
            // @ts-ignore
            .y((d: { lat: number; lng: number; }) => map.current?.target.latLngToLayerPoint([d.lat, d.lng]).y || 0);

        return line(points);
    };

    if (!isOpen) return null;

    // @ts-ignore
    return (
        <div className='popup_overlay' id='to_print' style={{ zIndex: 202 }} onClick={handleClose}>
            {mark && (
                <>
                    <button className='closeButton' onClick={onClose}>
                        <img src='/images/closeICON.svg' alt='close icon svg' />
                    </button>
                    <div className="popup_content" style={{ zIndex: 203 }} onClick={(e) => e.stopPropagation()} ref={printRef}>
                        <div className='popup_header'>
                            <div className='popup_header_first'>
                                <img src="/images/point.svg" alt="" />
                                <span>{mark.name}</span>
                            </div>
                        </div>
                        <div className='popup_flex'>
                            <div className='popup_image'>
                                <MapContainer
                                    //@ts-ignore [mapCenter[0], mapCenter[1] + 0.001]
                                    center={center}
                                    zoom={zoom}
                                    style={{ height: '100%', width: '100%', zIndex: 2 }}
                                    //@ts-ignore
                                    whenReady={(mapInstance) => {
                                        map.current = mapInstance; // Инициализируем map.current при создании карты
                                        handleMapLoad(); // Отмечаем, что карта загружена
                                    }}
                                >
                                    <TileLayer
                                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                        eventHandlers={{
                                            tileloadstart: handleTileLoadStart, // Подписка на начало загрузки тайла
                                            tileload: handleTileLoad,          // Подписка на завершение загрузки тайла
                                        }}
                                    />
                                </MapContainer>
                            </div>
                            <div className={styles.symbols}>
                                <h2 className={styles.symbols_header}>Условные обозначения</h2>
                                <div className={styles.symbols_content}>
                                    <div className={styles.symbols_block}>
                                        <img src="/images/arch.svg" alt="" />
                                        <span>Арка</span>
                                    </div>
                                    <div className={styles.symbols_block}>
                                        <img src="/images/entance.svg" alt="" />
                                        <span>Подъезд</span>
                                    </div>
                                    <div className={styles.symbols_block}>
                                        <img src="/images/exit.svg" alt="" />
                                        <span>Выход с игровой площадки</span>
                                    </div>
                                    <div className={styles.symbols_blockZone}>
                                        <div className={styles.symbols_blockZone_inside}>
                                            <img src="/images/playZone.svg" alt="" />
                                            <span>Игровая зона</span>
                                        </div>
                                        <div className={styles.playPattern} />
                                    </div>
                                    <div className={styles.symbols_blockZone}>
                                        <div className={styles.symbols_blockZone_inside}>
                                            <img src="/images/parking.svg" alt="" />
                                            <span>Парковка</span>
                                        </div>
                                        <div className={styles.parkPattern} />
                                    </div>
                                    <div className={styles.symbols_blockZone}>
                                        <div className={styles.symbols_blockZone_inside}>
                                            <img src="/images/road.svg" alt="" />
                                            <span>Проезжая часть</span>
                                        </div>
                                        <div className={styles.roadPattern} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default PrintingComponent;
